<template>
  <div class="word-list-content">
    <small>שם</small>
    <div class="bg-secondary mb-4 p-1 mt-1 col-5 rounded">{{localUserData.name}}</div>
    <small>דואר אלקטרוני</small>
    <div class="bg-secondary mb-4 p-1 mt-1 col-5 email-alignment rounded">{{localUserData.email}}</div>
    <div v-if="showDropboxId" class="profile-id">
      <small>מזהה משתמש</small>
      <div
          @click="copyId"
          dir="rtl"
          class="bg-secondary mb-4 py-1 mt-1 col-5 rounded">{{$store.state.account.userData.userId}}
        <i-clone
            v-b-tooltip.hover
            title="העתק מספר"
            class="position-absolute"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserProfile',
  props: {
    copyId: {},
    localUserData: {},
    showDropboxId: {}
  }
}
</script>
<style scoped lang="scss">
/* User-Settings-specific CSS goes here */
.profile-id {
  i {
    top: 8px;
    left: 12px;
  }

  div {
    cursor: pointer;
  }
}

.user-settings-choice p {
  /*margin: 0;*/
}

/*
    .user-settings-content {
        padding: 10px;
    }
*/
.user-settings-content h3 {
  /*padding: 10px;*/
}

.user-settings-content img {
  /*height: 100px;*/
  /*margin-bottom: 20px;*/
  /*margin-right: 20px;*/
}

u {
  cursor: pointer;
}

hr {
  margin-top: 0px;
}

.word-list-content {
  width: 100%;
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  margin-left: -10px;
}

@media (max-width: 991px) {
}
</style>