import { Dropbox, DropboxAuth } from 'dropbox'
import readFile from '../js/readFile'
import jszip from 'jszip'
import {INTERNAL_DROPBOX_LOAD, Mutations} from './store'
import { readZipContentsWithLoadAsync } from 'shared/js/readZipContents'
import firebase from 'firebase/app'
import 'shared/account/store/firebaseModule.js'
import {fetchHack} from './preflightHack.js'
import {firebaseStore} from '../account/index.js'

const COMPLETED = 'COMPLETED', SAVED = 'SAVED', OTHER_TOOL = 'OTHER_TOOL', NEW = 'NEW'
  , SETTINGS_FILE = 'SETTINGS_FILE', SETTINGS_FILENAME = 'DictaSettings.json'
let _store, _documentManager, _toolName, _onCompletionCallback, _noCompletingInProjects, _autoSaveInterval
  , _toolMustAllowLoadingJsonFiles
let _autoClear = true
let _settingsFileInFolder = {}
let partnersFolders = null
let lastLoadFilename = null
let _readonly = false
const saveCallbacks = []
const CLIENT_ID = 'os7bouk75dszo16'
// to distinguish between the old long-lived access tokens and refresh tokens, we add a prefix
const REFRESH_PREFIX = 'refresh:'


function getAccessToken() {
  if (process.env.VUE_APP_MORPHOLOGY_PARTNERS)
    return '5C7Ph4HUMtkAAAAAAAAAAdqLh1rlGTaOcxXiEgzRFewhN5PCrS4B8y9Z43wcwQAI'
  if (process.env.VUE_APP_OCR_PARTNERS)
    return 'dropbox access'
  if (_store.state.account.sync.dropbox_token) {
    //Save token in dropboxStatus for reloads when the firebox data is not yet available
    const newStatus = Object.assign({}, _store.state.dropbox.status)
    newStatus.accessToken = _store.state.account.sync.dropbox_token
    _store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
  }
  return _store.state.account.sync.dropbox_token ? _store.state.account.sync.dropbox_token :
    _store.state.dropbox.status && _store.state.dropbox.status.accessToken ? _store.state.dropbox.status.accessToken : null
}

// since getAccessToken checks both sync.dropbox_token and dropbox.status, we need to unset both
function disconnectFromDropbox() {
  const newStatus = Object.assign({}, _store.state.dropbox.status)
  newStatus.accessToken = null
  _store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
  firebaseStore('dropbox_token', null)
}

function fixPath(path) {
  // eslint-disable-next-line no-unused-vars
  const [_, folder, ...rest] = path.split('/')
  return {
    path: '/' + rest.join('/'),
    folder
  }
}

function fixPaths(args) {
  const obj = Object.assign({}, args)
  let dropboxKey, rootFolder, realFolder
  for (let key of ['path', 'from_path', 'to_path']) {
    if (obj[key]) {
      const { path, folder } = fixPath(obj[key])
      realFolder = partnersFolders[folder].folder
      rootFolder = folder
      dropboxKey = partnersFolders[folder].key
      obj[key] = realFolder + path
    }
  }
  if (obj.cursor) {
    const { cursor, partnersData } = obj.cursor
    obj.cursor = cursor;
    ({ dropboxKey, rootFolder, realFolder } = partnersData)
  }
  return {
    args: obj,
    dropboxKey,
    rootFolder,
    realFolder
  }
}
function waitForPartnerFolders() {
  if (partnerFoldersLoaded) return Promise.resolve()
  return new Promise(resolve => {
    const cb = () => {
      resolve()
      partnerCallbacks.delete(cb)
    }
    partnerCallbacks.add(cb)
  })
}

function parseDropboxKey(key) {
  if (!key) return { accessToken: null }
  if (key.startsWith(REFRESH_PREFIX))
    return { refreshToken: key.substring(REFRESH_PREFIX.length) }
  return { accessToken: key }
}

const dbxMap = new Map()
// Dropbox refresh tokens require an extra API call to get a short-lived access token. If we use a long-lived
// Dropbox object, then it can store the access token for a few hours, so it only needs to call the token endpoint
// once. We take a key, and use it to either look up an existing Dropbox object or create a new one, as needed.
function constructDropbox(key) {
  if (!dbxMap.has(key)) {
    const dropbox = new Dropbox(Object.assign({clientId: CLIENT_ID, fetch: fetchHack}, parseDropboxKey(key)))
    dbxMap.set(key, dropbox)
  }
  return dbxMap.get(key)
}
// We might need a different dbx object depending on which key the client is using.
// With accounts.dicta, a single client might get different files from different Dropbox accounts
// Also, all our functions pretend that there's a single namespace, but Dropbox doesn't know about it, of course.
// We rewrite the calls and responses to match the paths that our app expects.
function fixUp(func) {
  return (args) => waitForPartnerFolders().then(() => {
    const { dropboxKey, args: fixedArgs, rootFolder, realFolder } = fixPaths(args)
    const dbx = constructDropbox(dropboxKey)
    return dbx[func](fixedArgs).then(apiResult => {
      const result = apiResult.result
      if (result.entries)
        result.entries = result.entries.map(item => {
          if (item.path_display)
            item.path_display = item.path_display.replace(realFolder, '/' + rootFolder)
          return item
        })
      if (result.cursor) {
        result.cursor = {
          cursor: result.cursor,
          partnersData: {
            dropboxKey, rootFolder, realFolder
          }
        }
      }
      return apiResult
    })
  })
}

let partnersDbx = null
function getDbx() {
  if (process.env.VUE_APP_OCR_PARTNERS) {
    if (!partnersDbx) {
      startFirebasePartnerFolders()
      partnersDbx = Object.fromEntries([
        'filesDownload',
        'filesUpload',
        'filesListFolder',
        'filesCopyV2',
        'filesMoveV2',
        'filesDeleteV2',
        'filesListFolderLongpoll',
        'filesListFolderContinue',
      ].map(func => [func, fixUp(func)]))
    }
    return partnersDbx
  }
  return constructDropbox(getAccessToken())
}

function updateLoadedFileSettings(folder) {
  const SIMPLE_SUCCESS = true
  if (!(_settingsFileInFolder[folder])) {
    if (_store.state.dropbox.projectSettings !== null)
      _store.commit('SET_PROJECT_SETTINGS', null)
    return SIMPLE_SUCCESS
  }
  return getDbx().filesDownload({ path: folder + '/' + SETTINGS_FILENAME })
    .then(apiResult => {
      const file = apiResult.result
      readFile({
        file: file.fileBlob,
        name: file.name
      })
        .then(result => {
          try {
            _store.commit('SET_PROJECT_SETTINGS', JSON.parse(result.docText))
          }
          catch {
            if (_store.state.dropbox.projectSettings !== null)
              _store.commit('SET_PROJECT_SETTINGS', null)
          }
        })
    })
}

// A map of content_hash to a Promise that returns the results of the Dropbox download
const predicted = new Map()
// An array to track which content_hashes have been recently requested, so we can remove
// old files from memory. Older files are at the beginning of the array
const LRUPredicted = []

// Pass a filename that may be loaded in the future.
// The function then will try to preload it, and you can use getPredicted to try to load the file.
// We don't want to keep too many files in memory, so calling predict on a new file may release an old file.
// This function is aware of savedata ZIPs for JSON source files, and will actually load that instead if it's available.
// Since the data may change, we save the content_hash, not the name, and when loading, we check if the
// content_hash has changed. We only return the file if the content_hash is the same. Since we need to get updates
// to confirm that, we can only return a predicted file if the folder is currently being watched.
//
// It's possible that a predicted file may not have a save file, but then it gets saved, or it might have one, and then
// it gets deleted. Saving by content_hash helps protect against that case, too.
function predict(filename) {
  const folder = getFolder(filename)
  // if the folder isn't watched, nothing to do
  if (!lastEntries.has(folder))
    return
  const entry = lastEntries.get(folder).find(e => e.path_display === filename)
  // if the predicted file isn't found, nothing to do
  if (!entry)
    return
  const hash = entry.content_hash
  // was already predicted, so all we need to do is note that it's the most recent file to be predicted
  if (predicted.has(hash)) {
    // move to head of list
    LRUPredicted.splice(LRUPredicted.indexOf(hash), 1)
    LRUPredicted.push(hash)
    return
  }
  // new prediction
  LRUPredicted.push(hash)
  // expire an old prediction if we have too many
  if (LRUPredicted.length > 6) {
    const expired = LRUPredicted.shift()
    predicted.delete(expired)
  }
  let downloadName = filename
  if ([COMPLETED, SAVED].includes(entry.dictaStatus) && entry.has_savedata_zip)
      downloadName = getSaveName(filename) + '.zip'
  predicted.set(hash,
    getDbx().filesDownload({ path: downloadName })
      // try again
      .catch(() => getDbx().filesDownload({ path: downloadName }))
      // remove from predicted, so the app can try again
      .catch(e => {
        LRUPredicted.splice(LRUPredicted.indexOf(hash), 1)
        predicted.delete(hash)
        throw e
      })
  )
}

// returns a promise that we received from Dropbox's filesDownload, or undefined if we can't find one that we can use
function getPredicted(filename) {
  const folder = getFolder(filename)
  // check if we have an active watch we can use to confirm that we have valid data
  if (!lastEntries.has(folder))
    return
  const entry = lastEntries.get(folder).find(e => e.path_display === filename)
  // check if that file still exists
  if (!entry)
    return
  const hash = entry.content_hash
  return predicted.get(hash)
}

/**
 * dropboxLoad takes a filename, and loads the corresponding data
 * if there is a saved state, it loads that, otherwise it loads the original text
 * it loads via DocumentManager.load() or DocumentManager.importText() as appropriate
 * In order of preference, for a file called myfile.txt, for a tool called 'tool' it will try:
 *   .saved/_savedata_tool_myfile_txt.json.zip
 *   .saved/_savedata_tool_myfile_txt.json
 *   Completed/myfile.json
 *   .saved/myfile.json
 * @param dictaStatusEntry - file is loaded from entry.path_display. Also needed to tell if there is saved data and what format
 * @returns {PromiseLike<any> | Promise<any>} - so that the caller can .catch() errors
 */
async function dropboxLoad(dictaStatusEntry) {
  const filename = dictaStatusEntry.path_display
  const folder = getFolder(filename)
  await updateLoadedFileSettings(folder)
  const dbx = getDbx()
  let downloadPromise
  const pngName = folder + '/' + baseName(filename) + '.png'
  // this is safe even for non-ocr files since it does nothing unless the PNG file actually exists
  predict(pngName)
  // useLoad is a flag to say that we are loading a saved file, not an original, so we know what call to make to
  // DocumentManager
  let useLoad = false
  const predicted = getPredicted(filename)
  if ([COMPLETED, SAVED].includes(dictaStatusEntry.dictaStatus)) {
    useLoad = true
    if (dictaStatusEntry.has_savedata_zip)
      downloadPromise = predicted || dbx.filesDownload({path: getSaveName(filename) + '.zip'})
    else if (dictaStatusEntry.has_savedata_json)
      downloadPromise = dbx.filesDownload({ path: getSaveName(filename) })
    else
      downloadPromise = tryOldDownload(filename, dictaStatusEntry.dictaStatus)
  } else {
    downloadPromise = predicted || dbx.filesDownload({ path: filename })
  }
  let dropboxFile = (await downloadPromise).result
  var docContent
  if (dropboxFile.name.endsWith('.zip')) {
    if (useLoad) { // open the zip, and give load() the 'zipped file named contents.json'
      const zipContents = await readZipContentsWithLoadAsync(dropboxFile.fileBlob)
      docContent = zipContents['contents.json']
    } else docContent = dropboxFile.fileBlob // let importText() "handle" the "unzipping"
  } else {
    const result = await readFile({
      file: dropboxFile.fileBlob,
      name: dropboxFile.name
    })
    docContent = result.docText
  }
  // this is a callback for the loader to request a related resource
  // for example, the OCR project can call loader('PNG') and receive the related PNG
  const loader = async (resource) => {
    if (resource === 'PNG') {
      const predicted = getPredicted(pngName)
      if (predicted)
        return predicted.then(dropboxFileAPI => dropboxFileAPI.result.fileBlob)
      const dropboxFile = (await dbx.filesDownload({ path: pngName })).result
      return dropboxFile.fileBlob
    }
  }
  // at this point, the data should be loaded, and we are ready to update the UI
  lastLoadFilename = filename
  if (useLoad)
    await _documentManager.load(docContent, { loader })
  else
    await _documentManager.importText(docContent, { loader })
  _store.commit(Mutations.SET_DROPBOX_STATUS, { status: dictaStatusEntry, fileChange: INTERNAL_DROPBOX_LOAD })
}

function getDropboxSaveFile() {
  const internalData = _documentManager.save()
  const zip = jszip()
  zip.file('contents.json', internalData)
  return zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 6
    }
  })
}

// returns: a promise, allows the caller to wait for completion. On error, throws an error, possibly the error from
// the Dropbox SDK.
/* Saves the data, twice. Either by saving two files, or saving and copying (for those tools that don't have a save format)
 * When copying, the target, if it exists, must be deleted.
 * We always delete only after writing new data, except when we need to delete a file in order to allow a copy to be made.
 * Therefore, the Dropbox operations it will try (each only if necessary), for a file called myfile.txt, and a tool called 'tool':
 *   Upload .saved/_savedata_tool_myfile_txt.json.zip
 *   Delete .saved/_savedata_tool_myfile_txt.json
 *   If it has an export format with extension 'ext':
 *     Upload either .saved/myfile.ext or Completed/myfile.ext
 *     Delete myfile.ext from the other folder
 *   If not:
 *     Delete either .saved/myfile.json.zip or Completed/myfile.json.zip
 *     Copy the save file to that name
 *     Delete the other three possibilities (.saved/myfile.json, Completed/myfile.json, plus the zip it didn't just create)
 */
async function dropboxSave(status, completed, activeSave, exportOptions) {
  if (_readonly) throw 'Document is read only.'
  try {
    return await dropboxSaveImpl(status, completed, activeSave, exportOptions)
  } catch (e) {
    const newStatus = Object.assign({}, status)
    newStatus.saving = false
    newStatus.lastSaveSucceeded = false
    _store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
    throw e
  }
}
async function dropboxSaveImpl(status, completed, activeSave, exportOptions) {
  if (lastLoadFilename && status.path_display !== lastLoadFilename) {
    throw `dropboxSave failed: ${status.path_display} doesn't match ${lastLoadFilename}`
  }
  const newStatus = Object.assign({}, status)
  newStatus.saving = true
  newStatus.dictaStatus = completed ? COMPLETED : SAVED
  _store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
  const previousSavePath = getSaveName(status.path_display)
  const savePath = previousSavePath + '.zip'
  const exportData = _documentManager.exportText(exportOptions)
  const exportPath = getExportName(status.path_display, completed, exportData.extension)
  const dbx = getDbx()
  const zippedBlob = await getDropboxSaveFile()
  await dbx.filesUpload({
    contents: zippedBlob,
    path: savePath,
    mode: {
      '.tag': 'overwrite'
    },
    mute: true
  })
  newStatus.has_savedata_zip = true
  // now that the zip file is safely uploaded, remove the old save file, if it exists
  // don't make a concurrent request, or Dropbox may impose rate limits
  if (status.has_savedata_json) await dropboxDelete(previousSavePath).catch(() => { })
  newStatus.has_savedata_json = false
  if (exportData.useInternalFormat) {
    const zipExportPath = exportPath + '.zip'
    // the copy will fail if the previous file is still there, so delete it first
    // don't catch this error, unless the file has been deleted since we last checked,
    // because if there's any other error, the save is probably going to fail
    if (completed ? status.has_zip_in_completed : status.has_zip_in_saved)
      await dropboxDelete(zipExportPath).catch(e => {
        if (e?.error?.error?.path_lookup[".tag"] !== 'not_found') {
          throw e
        }
      })
    await dbx.filesCopyV2({
      from_path: savePath,
      to_path: zipExportPath,
      allow_shared_folder: true
    })
    if (completed)
      newStatus.has_zip_in_completed = true
    else
      newStatus.has_zip_in_saved = true
    // remove the old export files, if they exist
    if (status.has_ext_in_completed) {
      await dropboxDelete(getExportName(status.path_display, true, exportData.extension)).catch(() => { })
      newStatus.has_ext_in_completed = false
    }
    if (status.has_ext_in_saved) {
      await dropboxDelete(getExportName(status.path_display, false, exportData.extension)).catch(() => { })
      newStatus.has_ext_in_saved = false
    }
    // finally, remove the zip in the other dir (if completed, in saved, and vice versa)
    if (completed ? status.has_zip_in_saved : status.has_zip_in_completed) {
      // first get the name of the export file in the other dir
      const otherExportPath = getExportName(status.path_display, !completed, exportData.extension) + '.zip'
      await dropboxDelete(otherExportPath)
      if (completed)
        newStatus.has_zip_in_saved = false
      else
        newStatus.has_zip_in_completed = false
    }
  } else {
    // When we're not using the internal format, then this file isn't a ZIP, and doesn't require looking at other files
    // Uploads don't require deleting the target file first; we can just specify "overwrite"
    await dbx.filesUpload({
      contents: exportData.file,
      path: exportPath,
      mode: {
        '.tag': 'overwrite'
      }
    })
    if (completed)
      newStatus.has_ext_in_completed = true
    else
      newStatus.has_ext_in_saved = true
    // delete the other file, if it exists
    if (completed ? status.has_ext_in_saved : status.has_ext_in_completed) {
      // first get the name of the export file in the other dir
      const otherExportPath = getExportName(status.path_display, !completed, exportData.extension)
      await dropboxDelete(otherExportPath)
      if (completed)
        newStatus.has_ext_in_saved = false
      else
        newStatus.has_ext_in_completed = false
    }
  }
  newStatus.edited = false
  newStatus.lastSaved = new Date().getTime()
  newStatus.saving = false
  newStatus.lastSaveSucceeded = true
  _store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
  for (const cb of saveCallbacks) {
    cb()
  }
  if (completed) {
    if (_onCompletionCallback && activeSave) { // passive save of ALREADY "complete" doc isn't "completion"
      return _onCompletionCallback()
    }
  }
}

function dropboxDelete(path) {
  const dbx = getDbx()
  return dbx.filesDeleteV2({
    path: path
  })
}

function dropboxMove(src, dest) {
  const dbx = getDbx()
  return dbx.filesMoveV2({
    from_path: src,
    to_path: dest
  })
}

function dropboxCreateFolder(path) {
  return getDbx().filesCreateFolderV2({ path })
}

function getFolder(filepath) {
  const lastSlash = filepath.lastIndexOf('/')
  return filepath.substring(0, lastSlash)
}

function getDisplayFolderFromPath(filepath) {
  if (!filepath) return ''
  return getDisplayFromFolder(getFolder(filepath))
}

function getDisplayFromFolder(folder) {
  if (process.env.VUE_APP_MORPHOLOGY_PARTNERS)
    return folder.replace('/Morphology Partners/' + _store.state.account.userData.userId, '')
  return folder
}

function getSaveFolder(folder) {
  return folder + '/.saved'
}

function getExportFolder(folder, completed) {
  return folder + (completed ? '/Completed' : '/.saved')
}

function getSaveName(path) {
  return getSaveFolder(getFolder(path)) +
    '/_savedata_' + _toolName + '_' +
    baseName(path) + '_' + extension(path) + '.json'
}

function getOldSaveName(path, completed) {
  return getExportFolder(getFolder(path), completed) +
    '/' +
    baseName(path) + '.json'
}

function tryOldDownload(filename, status) {
  return getDbx().filesDownload({ path: getOldSaveName(filename, status === COMPLETED) })
}

function getExportName(path, completed, extension) {
  return getExportFolder(getFolder(path), completed) +
    '/' +
    baseName(path) + '.' + extension
}

function dropboxInitialize({ store, documentManager, toolName, // required arguments
  autoSaveInterval, // the below 3 are falsy if omitted
  onCompletionCallback, noCompletingInProjects, toolMustAllowLoadingJsonFiles,
  autoClear // true if omitted
}) {
  _store = store
  _documentManager = documentManager
  _toolName = toolName.replace(/_/g, '-')
  _onCompletionCallback = onCompletionCallback
  _noCompletingInProjects = noCompletingInProjects
  _autoSaveInterval = autoSaveInterval ? autoSaveInterval : 300000 // i.e. every five minutes
  _toolMustAllowLoadingJsonFiles = toolMustAllowLoadingJsonFiles
  // autoClear is true if it's undefined, but otherwise is a boolean
  _autoClear = autoClear === undefined || !!autoClear
}

async function runWatchLoop(path, callback, errorCallback, stopObject) {
  if (!getAccessToken()) {
    // dropbox token may not yet be available; wait a few seconds
    await new Promise(r => { setTimeout(() => r(), 5000)})
  }
  const dbx = getDbx()
  let cursor
  let has_more = false
  let entries = []
  while (stopObject.flag) {
    try {
      const firstRun = (await dbx.filesListFolder({ path })).result
      cursor = firstRun.cursor
      has_more = firstRun.has_more
      entries = firstRun.entries
      callback(entries.slice())
      while (stopObject.flag) {
        let longpoll
        if (!has_more) {
          longpoll = (await dbx.filesListFolderLongpoll({ cursor })).result
        }
        if (has_more || longpoll.changes) {
          const update = (await dbx.filesListFolderContinue({ cursor })).result
          cursor = update.cursor
          has_more = update.has_more
          const deletedNames = update.entries.filter(e => e['.tag'] === 'deleted').map(e => e.name)
          const added = update.entries.filter(e => e['.tag'] !== 'deleted')
          const modifiedNames = added.map(newE => newE.name)
            .filter(newEname => entries.map(oldE => oldE.name).includes(newEname))
          entries = entries.filter(e => !(deletedNames.concat(modifiedNames).includes(e.name))).concat(added)
          callback(entries.slice())
        }
      }
    } catch (e) {
      if (e?.error && e.error['.tag'] === 'reset') {
          // cursor became invalid - should we handle?
      } else {
        if (errorCallback)
          errorCallback(e)
      }
      let resolver
      setTimeout(() => resolver(), 10000)
      await new Promise(resolve => resolver = resolve)
    }
  }
}

function watchFolder(path, callback, errorCallback) {
  let stopObject = { flag: true }
  runWatchLoop(path, callback, errorCallback, stopObject)
  return () => { stopObject.flag = false }
}

function baseName(path) {
  const lastSlash = path.lastIndexOf('/')
  let name = path.substring(lastSlash + 1)
  // special case: basename('basename.json.zip') returns 'basename'
  if (name.endsWith('.zip')) {
    name = name.substring(0, name.length - 4)
  }
  const lastDot = name.lastIndexOf('.')
  return lastDot === -1 ? name : name.substring(0, lastDot)
}

function extension(path) {
  return path.substring(path.lastIndexOf('.') + 1)
}

function _watchFolderWithStatus(path, callback, errorCallback) {
  let stopObject = { flag: true }, stopFolder, stopSaved, stopCompleted
  let saved = new Map(),
    completed = new Map(),
    otherTool = new Map(),
    storedEntries = [],
    savedEntries = [],
    completedEntries = []
  // we don't want a user to click on a file before .saved is loaded,
  // because it will import the file instead of reading the saved file
  let waitForSaved = false;
  function resolve() {
    if (waitForSaved) { return }
    const results = []
    let settingsFileInFolder = false
    for (let e of storedEntries) {
      const base = baseName(e.name)
      const savename = getSaveName(e.path_display)
      const savedata_zip = savedEntries.filter(entry => entry.path_display === savename + '.zip')
      const savedata_json = savedEntries.filter(entry => entry.path_display === savename)
      // the next two can both be true
      const ext_in_saved = savedEntries
        .filter(entry => entry.name.startsWith(base + '.') && !entry.name.endsWith('.json.zip'))
      const zip_in_saved = savedEntries.filter(entry => entry.name === base + '.json.zip')
      const ext_in_completed = completedEntries
        .filter(entry => entry.name.startsWith(base + '.') && !entry.name.endsWith('.json.zip'))
      const zip_in_completed = completedEntries
        .filter(entry => entry.name === base + '.json.zip')
      const dictaFiles = [
        savedata_zip, savedata_json, ext_in_saved, zip_in_saved, ext_in_completed, zip_in_completed,
        otherTool.has(base) ? [otherTool.get(base)] : []
      ]
        .flat().map(entry => entry.path_display)
      const entry = Object.assign({
        dictaStatus: NEW,
        savename: savename,
        output_extension: null,
        has_savedata_zip: savedata_zip.length > 0,
        has_savedata_json: savedata_json.length > 0,
        // the next two can both be true
        has_ext_in_saved: ext_in_saved.length > 0,
        has_zip_in_saved: zip_in_saved.length > 0,
        has_ext_in_completed: ext_in_completed.length > 0,
        has_zip_in_completed: zip_in_completed.length > 0,
        dictaFiles,
        lastSaved: null,
        lastSaveSucceeded: null,
        saving: false,
      }, e)
      if (entry.name === SETTINGS_FILENAME) {
        entry.dictaStatus = SETTINGS_FILE
        settingsFileInFolder = true
      } else if (otherTool.has(base)) {
        entry.dictaStatus = OTHER_TOOL
      } else if (completed.has(base)) {
        entry.dictaStatus = COMPLETED
        entry.server_modified = completed.get(base).server_modified
        entry.output_extension = extension(completed.get(base).name)
      } else if (saved.has(base)) {
        entry.dictaStatus = SAVED
        entry.server_modified = saved.get(base).server_modified
        entry.output_extension = extension(saved.get(base).name)
      }
      if (entry.has_savedata_zip) {
        entry.content_hash = savedata_zip[0].content_hash
      }
      results.push(entry)
    }
    _settingsFileInFolder[path] = settingsFileInFolder
    updateLoadedFileSettings(path) // so the initialization button will eventually be shown/hidden
    if (stopObject.flag) {
      callback(results)
    }
  }
  stopFolder = watchFolder(path, entries => {
    // prevent race condition, if we just received a new saved or Completed folder, and stop has already been called,
    // don't start watching those folders
    if (!stopObject.flag) return
    storedEntries = entries
    const foundSaved = entries.some(entry => entry.name === '.saved')
    const foundCompleted = entries.some(entry => entry.name === 'Completed')
    // if there is a Completed folder, watch that, too, to check if a file is completed
    // we also need to know if there's a .json file or a .json.zip file, for the files that use the internal save format
    if (!stopCompleted && foundCompleted) {
      stopCompleted = watchFolder(getExportFolder(path, true), entries => {
        completedEntries = entries
        const completedEntryPairs = entries.map(entry => [baseName(entry.name), entry])
        // a file is considered completed if a file with the same base name exists in the Completed folder
        completed = new Map(completedEntryPairs)
        resolve()
      })
    }
    // if there is a saved folder, watch that, too, to find saved data
    // there can be saved data in myfile.json, _savedata_tool_myfile.json, or _savedata_tool_myfile.json.zip
    if (!stopSaved && foundSaved) {
      waitForSaved = true
      stopSaved = watchFolder(getSaveFolder(path), entries => {
        waitForSaved = false
        savedEntries = entries
        // we have save files named with a special format
        // we want to know if the save file is a zip or not, and if it belongs to this tool
        const saveDataFiles = entries.map(entry => {
          // break into toolname, basename, and original extension
          const segments = entry.name.match(/^_savedata_([^_]+)_(.*)_([^_]+)(.json)(.zip)?$/)
          if (segments) {
            entry.is_save_zip_format = !!segments[5]
            return {
              toolName: segments[1],
              baseName: segments[2],
              originalExt: segments[3],
              entry
            }
          }
          return null
        }).filter(e => e !== null)
        // consolidate 'myfile.json' type entries with the _savedata_ type entries from above
        const saveEntryPairs = entries.map(entry => [baseName(entry.name), entry])
          .concat(saveDataFiles.map(saveData => [saveData.baseName, saveData.entry]))
        saved = new Map(saveEntryPairs)
        otherTool = new Map(saveDataFiles
          .filter(saveData => saveData.toolName !== _toolName)
          .map(saveData => [saveData.baseName, saveData.entry])
        )
        resolve()
      })
    }
    if (stopCompleted && !foundCompleted) {
      // watching, but the Completed folder is gone, so stop watch
      stopCompleted()
      completed = new Map()
      // this doubles as our flag about whether there's a running watch, so reset it
      stopCompleted = null
    }
    if (stopSaved && !foundSaved) {
      // watching, but the .saved folder is gone, so stop watch
      stopSaved()
      saved = new Map()
      // this doubles as our flag about whether there's a running watch, so reset it
      stopSaved = null
    }
    resolve()
  }, errorCallback)
  return () => {
    stopObject.flag = false
    if (stopFolder) stopFolder()
    if (stopSaved) stopSaved()
    if (stopCompleted) stopCompleted()
  }
}

let watchCache = new Map()
let watchCallbacks = new Map()
let lastEntries = new Map()

let firebasePartnerFoldersStarted = false
let partnerFoldersLoaded = false
let partnerCallbacks = new Set()
function startFirebasePartnerFolders() {
  if (firebasePartnerFoldersStarted) return
  firebasePartnerFoldersStarted = true
  firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      partnersFolders = null
      partnerFoldersLoaded = true
      return
    }

    // Gather both folders from the user and from the user's groups
    let userFolders = {} // This object will contain folders
    const groupsFolders = {} // This object will contain objects, each containing folders

    const combinedCB = () => {
      let allFolders = {...userFolders}
      for (const groupFolders of Object.values(groupsFolders)) {
        allFolders = {...allFolders, ...groupFolders}
      }
      partnersFolders = allFolders
      for (let cb of partnerCallbacks) cb(allFolders)
    }

    // Get folders from the user itself
    const userFoldersRef = firebase.database().ref('/dropbox/' + user.uid + '/folders')
    userFoldersRef.on('value', snapshot => {
      userFolders = snapshot.val()
      partnerFoldersLoaded = true
      combinedCB()
    })

    // Get folders from the user's groups
    const groupIdsRef = firebase.database().ref('/dropbox/' + user.uid + '/groups')
    groupIdsRef.on('value', snapshot => { // Get the user's groups
      const groupIds = Object.values(snapshot.val() ?? {})

      groupIds.forEach(groupId => { // For each group, get its folders
        const groupRef = firebase.database().ref('/dropbox/groups/' + groupId + '/folders')
        groupRef.on('value', snapshot => {
          const groupFolders = snapshot.val()
          groupsFolders[groupId] = groupFolders
          combinedCB()
        }, console.error) // eslint-disable-line no-console
      })
    })
  })
}

function watchPartnerRootFolder(callback) {
  startFirebasePartnerFolders()
  const cb = folders => {
    if (!folders)
      callback([])
    else
      callback(Object.keys(folders).map(folder => ({
        name: folder,
        '.tag': 'folder',
        path_display: '/' + folder,
        key: folders[folder].key
      })))
  }
  partnerCallbacks.add(cb)
  if (partnerFoldersLoaded)
    cb(partnersFolders)
  return () => {
    partnerCallbacks.delete(cb)
  }
}

function watchFolderWithStatus(path, callback, errorCallback) {
  const callbacks = { callback, errorCallback }
  // if we're already watching this path
  if (watchCache.has(path)) {
    // just add these callbacks to the list
    watchCallbacks.get(path).push(callbacks)
    // call the callback with entries if we have them
    if (lastEntries.has(path)) {
      callback(lastEntries.get(path))
    }
    // eslint-disable-next-line no-console
    console.log('reusing watch for '  + (path || 'top folder'))
  } else {
    // we're not watching, so we may need to create a new callback array
    if (!watchCallbacks.has(path)) {
      watchCallbacks.set(path, [callbacks])
    } else {
      watchCallbacks.get(path).push(callbacks)
    }
    // eslint-disable-next-line no-console
    console.log('new watch for ' + (path || 'top folder'))
    let stopWatching
    const callCallbacks = entries => {
      lastEntries.set(path, entries)
      for (let callbacks of watchCallbacks.get(path)) {
        callbacks.callback(entries)
      }
    }
    const callErrorCallbacks = error => {
      for (let callbacks of watchCallbacks.get(path)) {
        if (callbacks.errorCallback)
          callbacks.errorCallback(error)
      }
    }
    if (process.env.VUE_APP_OCR_PARTNERS && (path === '' || path === '/')) {
      stopWatching = watchPartnerRootFolder(callCallbacks, callErrorCallbacks)
    } else {
      stopWatching = _watchFolderWithStatus(path, callCallbacks, callErrorCallbacks)
    }
    watchCache.set(path, stopWatching)
  }
  let callbacksStopped = false
  // return a function to stop watching
  return () => {
    if (callbacksStopped) {
      // eslint-disable-next-line no-console
      console.error('stop watch called twice for ' + path)
      return
    }
    callbacksStopped = true
    // first, remove our callback
    const callbackList = watchCallbacks.get(path)
    callbackList.splice(callbackList.indexOf(callbacks), 1)
    // eslint-disable-next-line no-console
    console.log('removing callback for ' + (path || 'top folder'))
    if (callbackList.length !== 0) {
      return
    }
    // eslint-disable-next-line no-console
    console.log('... and setting timer')
    // next, start a timer to remove the watch from the cache
    setTimeout(() => {
      // if someone else started using the watch, then it will appear in watchCallbacks
      // if not, we can stop the watch
      if (callbackList.length === 0) {
        const stopWatching = watchCache.get(path)
        // if two watches stopped at once, only one should do the cleanup
        if (stopWatching) {
          watchCache.delete(path)
          lastEntries.delete(path)
          stopWatching()
          // eslint-disable-next-line no-console
          console.log('stopping watch for ' + (path || 'top folder'))
        }
      }
    }, 60000)
  }
}

function dropboxSetCompleted(entry, completed) {
  // this function can no longer move files that predate the _savedata_ save format
  const exportedFiles = entry.dictaFiles.filter(filename => !filename.includes('/_savedata_'))
  if (exportedFiles.length !== 1)
    return Promise.reject('Can only toggle completed status if there is exactly one save file.')
  const existingFilename = exportedFiles[0]
  const wasCompleted = existingFilename.includes('/Completed/')
  const wasSaved = existingFilename.includes('/.saved/')
  if ((completed && wasCompleted) || (!completed && wasSaved))
    return Promise.resolve('Nothing to do.')
  const newFilename = completed ?
    existingFilename.replace('/.saved/', '/Completed/') :
    existingFilename.replace('/Completed/', '/.saved/')
  return dropboxMove(existingFilename, newFilename)
}

function getToolName() {
  return _toolName
}

function toolMustAllowLoadingJsonFiles() {
  return _toolMustAllowLoadingJsonFiles
}

function noCompletingInProjects() {
  return _noCompletingInProjects
}

function getAutoSaveInterval() {
  return _autoSaveInterval
}

function clearDropboxData(auto) {
  if ((auto && _autoClear) || !auto) {
    //this.$store.commit('SET_DROPBOX_STATUS', null) // the "watch" will update localStorage
    _store.commit(Mutations.SET_DROPBOX_STATUS, null)
    _store.commit(Mutations.SET_PROJECT_SETTINGS, null)
  }
}

function addSaveCallback(cb) {
  saveCallbacks.push(cb)
}

const REDIRECT_URI = location.origin + '/dropbox-auth'
async function getDropboxAuthUrl() {
  const dropboxAuth = new DropboxAuth({ clientId: CLIENT_ID, fetch: fetchHack })
  const url = await dropboxAuth.getAuthenticationUrl(REDIRECT_URI, undefined, 'code', 'offline', undefined, undefined, true)
  window.sessionStorage.setItem('dbxCodeVerifier', dropboxAuth.codeVerifier)
  return url
}

async function processDbxLogin() {
  const params = new URL(window.location).searchParams
  const dbxAuth = new DropboxAuth({
    clientId: CLIENT_ID,
  });
  dbxAuth.setCodeVerifier(window.sessionStorage.getItem('dbxCodeVerifier'));
  const tokenData = await dbxAuth.getAccessTokenFromCode(REDIRECT_URI, params.get('code'))

  firebaseStore('dropbox_token', REFRESH_PREFIX + tokenData.result.refresh_token)
}

let readonlyCBs = []
function setReadonly(readonly) {
  _readonly = readonly
  for (let cb of readonlyCBs) {
    cb(readonly)
  }
}

function addReadonlyCB(cb) {
  cb(_readonly)
  readonlyCBs.push(cb)
}

function removeReadonlyCB(cb) {
  readonlyCBs = readonlyCBs.filter(i => i !== cb)
}

export {
  dropboxInitialize,
  dropboxLoad,
  getDropboxSaveFile,
  dropboxSave,
  dropboxDelete,
  dropboxMove,
  dropboxCreateFolder,
  dropboxSetCompleted,
  getAccessToken,
  getDbx,
  getFolder,
  getDisplayFolderFromPath,
  getToolName,
  toolMustAllowLoadingJsonFiles,
  noCompletingInProjects,
  getAutoSaveInterval,
  watchFolder,
  watchFolderWithStatus,
  clearDropboxData,
  predict,
  addSaveCallback,
  getDropboxAuthUrl,
  processDbxLogin,
  disconnectFromDropbox,
  setReadonly,
  addReadonlyCB,
  removeReadonlyCB
}
