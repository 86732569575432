import { render, staticRenderFns } from "./TextPanelToken.vue?vue&type=template&id=684d80dc&scoped=true&"
import script from "./TextPanelToken.vue?vue&type=script&lang=js&"
export * from "./TextPanelToken.vue?vue&type=script&lang=js&"
import style0 from "./TextPanelToken.vue?vue&type=style&index=0&id=684d80dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../morphology/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684d80dc",
  null
  
)

export default component.exports