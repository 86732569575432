<template>
  <div id="app" class="h-100">
    <div class="mobile-msg position-absolute d-md-none d-block text-white h-100 text-center bg-primary pt-5">
      <div class="logo-holder">
        <img style="width:150px;" class="logo" src="@/assets/logo.png"></div>
        <h2 class="f-narkis py-5">
          <span class="hebrew-message">הכלי אינו זמין בנייד</span>
        </h2>
    </div>
    <header
      class="bg-white main-header"
      :class="{'partners':partners}"
      >
      <dicta-header :englishSupported="false">
        <template slot="endContent">
          <LoginLogout  :menu="menu" :promptUnsavedChanges="this.dropboxStatus !== undefined" @menu-item-clicked="menuItemClicked" @logout-clicked="logoutClicked" ref="loginLogout"></LoginLogout>
          <UserSettingsPopup :tab="tab" :dictionaryHeading="'מאגר מורפולוגי'" :negativeDictionaryHeading="'מורפולוגיות פגומות'" ref="user-settings" ></UserSettingsPopup>
        </template>
      </dicta-header>
      <inner-header
        title="תיוג מורפולוגי">
        <template v-slot:logo>
          <img @click="navHome" class="d-inline-block" src="./assets/logo.png"  alt=""/>
        </template>
        <template v-slot:show-shortcuts>
          <a style="cursor:pointer" class="text-white m-3 border-bottom pt-1 d-none d-md-inline" @click="showShortcutsObj.showShortcuts = true">
            קיצורי מקלדת
          </a>
        </template>
        <template v-slot:show-how-to-use>
          <b-button variant="primary" class="how-btn btn btn-outline-light btn-primary" size="md" @click="showAbout = true">
            איך זה עובד
          </b-button>
        </template>
      </inner-header>
    </header>
    <router-view></router-view>
    <div v-if="nakdanRunning" class="spinner position-absolute">
      <spinner></spinner>
    </div>
    <ServerFailedPopup v-if="showFailed" v-model="showFailed"></ServerFailedPopup>
    <AboutDialog v-if="showAbout" v-model="showAbout"></AboutDialog>
    <keyboard-shortcuts-container :show-shortcuts-obj="showShortcutsObj"></keyboard-shortcuts-container>
  </div>
</template>

<script>
import InnerHeader from 'shared/components/InnerHeader/InnerHeader'
import AboutDialog from '@/components/AboutDialog'
import KeyboardShortcutsContainer from '@/components/KeyboardShortcutsContainer'
import LoginLogout from 'shared/account/components/LoginLogout'
import UserSettingsPopup from '@/components/UserSettingsPopup'
import Spinner from 'shared/components/spinner'
import ServerFailedPopup from 'shared/components/ServerFailedPopup'
import {RunStates} from '@/store/runStates'
import _ from 'lodash'

export default {
  name: 'app',
  components: {
    InnerHeader,
    AboutDialog,
    KeyboardShortcutsContainer,
    LoginLogout,
    UserSettingsPopup,
    Spinner,
    ServerFailedPopup
  },
  data () {
    return {
      tab: {
        activeTab: 'profile',
        activeSet: 'profile'
      },
      showShortcutsObj: {
        showShortcuts: false
      },
      showAbout: false,
      partners: process.env.VUE_APP_MORPHOLOGY_PARTNERS,
      menu: [{title: 'ניהול פרוייקטים', data: 'projects'}, {title: 'פרופיל', data: 'profile'}, {title: 'מאגר מורפולוגי', data: 'word-list'}, {title: 'מורפולוגיות פגומות', data: 'negative-word-list'}]
    }
  },
  computed: {
    nakdanRunning() {
      return this.$store.state.nakdanApiState === RunStates.RUNNING
    },
    dropboxStatus () {
      return this.$store.state.dropbox.status
    },
    showFailed: {
      get () {
        return this.$store.state.nakdanApiState === RunStates.TIMED_OUT || this.$store.state.nakdanApiState === RunStates.FAILED
      },
      set () {
        this.$store.commit('NAKDAN_NOT_RUN')
      }
    }
  },
  watch: {

  },
  methods: {
    logoutClicked () {
      //delete this.$store.state.lastLoadedState.version;
      let compareSavedToCurrent = _.isEqual( _.omit(this.$store.state.lastLoadedState, ['version', ]), this.$store.state.morphologyApp)
      if(!compareSavedToCurrent) {
        //changes were made
        this.$refs.loginLogout.showUnsavedChangesPopup = true
      } else {
        //signouout
        this.$refs.loginLogout.signOut()
      }
    },
    menuItemClicked(menuItem) {
      if (menuItem.data === 'projects' && this.$route.name !== 'Projects')
        this.$router.push('projects')
      else if (menuItem.data === 'projects' && this.$route.name === 'Projects') {
        //stay where you are
      }

      else {
        this.tab.activeTab = menuItem.data
        this.tab.activeSet = menuItem.data
        this.$refs['user-settings'].$children[0].show() // the $ref is the COMPONENT, $children[0] is the modal
      }
    },
    navHome () {
      if (!this.partners)
        this.$router.push('/')
    }
  }
 }
</script>

<style>
  @media only screen and (max-width: 767px) {
    html, body, #app {height: 100%;overflow:hidden}
    .mobile-msg {
      left: 0;
      top: 0;
      z-index: 999999999;
      height:100%;
      width:100%;
    }
  }
</style>

<style lang="scss">
  .partners .logo{
    img, div {
      cursor: auto!important;
    }
  }
  .tooltip-inner {
    max-width: 160px;
  }
  .notify-msg {
    position: fixed;
    right: 24px;
    bottom: 24px;
    width: 238px;
    height: 50px;
    padding: 15px 8px;
    text-align: center;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #494949;
    z-index: 999999;
    color: white;
  }
  .wordlist-file-buttons {
    display: none;
  }
  .word-list-content .content-word {
    span, small {
      font-family: Roboto, mft_narkisclassic, sans-serif;
    }
  }
  .dictionary-button {
    border-radius: 15px;
    padding: 1px;
    width: 16px;
    height: 16px;
    text-align: center;
    margin-right: 2px;
    color: #989a97!important;
    font-size: 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
    &:hover {
      background: #f6f6f6;
    }
  }
  .user-settings-set-selector {
    width: auto!important;
    min-width: 115px;
  }
  .user-settings-set-selector:last-of-type {
    //visibility: hidden;
  }
  .top-bar{
    margin: 0!important;
    padding: 5px 0;
  }
  #app {
    background: #fbfbfb;
  }
  .results-toolbar {
    background: #e3e3e3;
  }
  @media (min-width: 1200px) {
    .results-toolbar.container {
      max-width: 100%;
    }
  }
  @font-face {
    font-family: 'mft_narkisclassic';
    src: url('assets/fonts/mft_narkisclassic-regular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    }
    @font-face {
      font-family: 'mft_narkisclassic';
      src: url('assets/fonts/MFTW_NarkisClassicBold.woff') format('woff');
      font-style: normal;
      font-weight: bold;
    }

    a{
      cursor: pointer;
    }
    .f-narkis{
      font-family: Roboto, mft_narkisclassic, sans-serif;
      line-height: 1.5;
    }

  .how-btn {
    margin-top: 3px;
  }
</style>
