<template>
  <div class="morphology-popup" v-hotkey="keymap">
    <b-modal
      id="morph-popup"
      v-model="show"
      centered
      hide-footer
      size="xl"
      @show="modalShow()"
    >
      <template slot="modal-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <b-button
                @click="close"
                variant="link"
                class="shadow-none border-0 position-absolute"
                style="font-size: 30px; top: -10px; right: 0"
              >
                &times;
              </b-button>
              <h2 class="my-1 d-inline-block pr-4">ניתוח מורפולוגי</h2>
            </div>
            <div class="col-sm-6 text-left">
              <b-button
                variant="light"
                class="d-none text-underline text-primary mx-2 px-0 mt-n1"
                :disabled="chosenSpeechPart === 'POS_UNKNOWN'"
                size="lg"
                @click="reset"
              >
                אפס נתונים
              </b-button>
              <b-button
                v-if="canDelete"
                variant="secondary"
                @click="deleteMorphology"
                ><i class="fas fa-trash-alt" />
              </b-button>
              <b-modal
                v-if="cannotDeleteMorph"
                id="text-still-loading"
                size="sm"
                hide-backdrop
                hide-header
                body-class="text-center"
                footer-class="border border-top border-secondary py-2"
                v-model="cannotDeleteMorph"
                ok-only
                ok-title="הבנתי"
              >
                לא ניתן למחוק מורפולוגיה זו,
                <br />
                כיון שהיא בשימוש
              </b-modal>
              <b-button
                v-b-tooltip.hover
                title="Esc"
                id="cancel-btn"
                variant="secondary"
                class="mr-2 d-none"
                @click="close"
                style="width: 112px"
              >
                ביטול
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Enter"
                id="save-btn"
                variant="primary"
                class="mr-2"
                @click="checkLogin"
              >
                {{
                  editMode
                    ? "שמור שינויים"
                    : newMorph
                    ? "צור מורפולוגיה"
                    : "שמור ושכפל"
                }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <div class="container">
        <div class="mb-3 rounded prefix-wrap row bg-background no-gutters">
          <div class="f-narkis selected-text rounded-right col-9 pr-3">
            <span
              class="morphExp"
              :class="{
                active:
                  searchItem.actualIndex === currentWordIndex ||
                  searchItem.groupRange.indexOf(currentWordIndex) >= 0,
              }"
              v-for="(searchItem, mli) in searchString"
              :key="mli"
            >
              <span
                v-if="
                  searchItem.actualIndex !== currentWordIndex &&
                  searchItem.groupRange.indexOf(currentWordIndex) < 0
                "
              >
                {{ searchItem.text }}</span
              >
              <span v-else>
                <span v-if="prefixLength === 0">
                  {{ searchItem.text }}
                </span>
                <span v-else>
                  <span
                    v-for="(currentItem, cli) in searchItem.text.split(
                      /(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ\|])/
                    )"
                    :key="cli"
                    class="d-inline-block"
                    :class="{
                      prefix:
                        cli < prefixLength && searchItem.text === currentWord,
                    }"
                  >
                    {{ currentItem }}
                  </span>
                </span>
              </span>
            </span>
            <span v-if="searchString.length > 15 && !lastWord">...</span>
          </div>
          <div class="rounded position-relative lex-wrap col-3 pr-4">
            <div class="d-flex bg-secondary h-100 pt-1 pl-1">
              <small class="d-inline-block mx-2 mt-1">לקסמה</small>
              <b-dropdown
                :text="!newMorphOption.lexEnabled ? 'לא ידוע' : currentLex"
                :disabled="
                  currentMorphData[this.currentWordIndex].groupRange.length > 0
                "
                class="d-dropdown d-inline-block bg-white flex-grow-1"
                :class="{ 'current-unk': currentLex == lexOptions[0] }"
                toggle-class="bg-white text-dark shadow-none"
                variant="outline-dark"
              >
                <b-dropdown-item
                  v-for="(item, mli) in lexOptions"
                  :active="item === currentLex"
                  :key="mli"
                  @click="(val) => setLexValue(lexOptions.indexOf(item))"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
              <!--
              :options="lexOptions"
              @change="val => setLexValue(lexOptions.indexOf(val))"
            -->
              <div
                class="xd-inline-block px-2 lex-enable d-none"
                v-if="
                  !newMorph &&
                  newMorphOption.word &&
                  newMorphOption.word.length > 0
                "
              >
                <b-form-checkbox v-model="lexEnabled" @change="setLex"
                  >לקסמה
                </b-form-checkbox>
              </div>
            </div>
            <!--   <div class="d-inline-block px-2 prefix-len-wrap">
            <small class="ml-2">תווי תחילית</small>
           
          </div>   -->
          </div>
        </div>
        <div class="row text-left" dir="ltr">
          <div class="col-lg-3 col-md-4 mb-3">
            <div class="morph-box pos">
              <div class="morph-name bg-background position-relative">
                <div class="d-inline-block">
                  <b-form-checkbox
                    v-model="posChecked"
                    switch
                    tabindex="-1"
                    @change="
                      setFirstChecked(
                        posChecked,
                        'chosenSpeechPart',
                        'baseformPosList'
                      )
                    "
                    id="switch-Part-of-speech"
                  >
                  </b-form-checkbox>
                </div>
                <span>Part of Speech</span>
                <div
                  class="position-absolute"
                  @click="setFlag('table', 'Part-of-speech')"
                >
                  <i
                    aria-hidden="true"
                    :class="{
                      'text-primary': isFlagged('table', 'Part-of-speech'),
                    }"
                    class="fa fa-flag mr-1"
                  ></i>
                </div>
              </div>
              <div class="morph-values" @click="posChecked = true">
                <b-form-select
                  @change="setFirstLast('chosenSpeechPart', 'baseformPosList')"
                  @input="posChecked = true"
                  v-model="chosenSpeechPart"
                  id="Part-of-speech"
                  :options="baseformPosList"
                  :class="{
                    disabled: !posChecked || chosenSpeechPart === 'Unspecified',
                  }"
                  autofocus
                  :select-size="15"
                >
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8" id="right-side-tables">
            <div class="row">
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showGenderList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="genderChecked"
                        id="switch-Gender"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            genderChecked,
                            'chosenGender',
                            'baseformGenderList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Gender</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Gender')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Gender'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="genderChecked = true">
                    <b-form-select
                      v-model="chosenGender"
                      id="Gender"
                      :options="baseformGenderList"
                      @input="genderChecked = true"
                      @change="
                        setFirstLast('chosenGender', 'baseformGenderList')
                      "
                      :class="{
                        disabled:
                          !genderChecked || chosenGender === 'Unspecified',
                      }"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showNumberList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="numberChecked"
                        id="switch-Number"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            numberChecked,
                            'chosenNumber',
                            'baseformNumberList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Number</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Number')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Number'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="numberChecked = true">
                    <b-form-select
                      v-model="chosenNumber"
                      id="Number"
                      :options="baseformNumberList"
                      @change="
                        setFirstLast('chosenNumber', 'baseformNumberList')
                      "
                      @input="numberChecked = true"
                      :class="{
                        disabled:
                          !numberChecked || chosenNumber === 'Unspecified',
                      }"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showPersonList"
              >
                <div class="morph-box" tabindex="0" id="Person">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="personChecked"
                        id="switch-Person"
                        tabindex="-1"
                        switch
                      >
                      </b-form-checkbox>
                      <!--@change="setFirstChecked(personChecked, 'chosenPerson', 'baseformPersonList')"-->
                    </div>
                    <span>Person</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Person')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Person'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values">
                    <b-form-checkbox-group
                      stacked
                      v-model="chosenPerson"
                      :options="baseformPersonList"
                      @change="
                        personChecked = chosenPerson && chosenPerson.length > 0
                      "
                      :class="{ disabled: !personChecked }"
                    ></b-form-checkbox-group>
                  </div>
                </div>
              </div>
              <!-- Status for noun-->
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="
                  displayMorphObject.showStatusList &&
                  chosenSpeechPart === 'Noun'
                "
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="statusChecked"
                        id="switch-Status"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            statusChecked,
                            'chosenStatus',
                            'baseformStatusList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Status</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Status')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Status'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="statusChecked = true">
                    <b-form-select
                      v-model="chosenStatus"
                      id="Status"
                      :options="baseformStatusList"
                      @change="
                        setFirstLast('chosenStatus', 'baseformStatusList')
                      "
                      @input="statusChecked = true"
                      :class="{
                        disabled:
                          !statusChecked || chosenStatus === 'Unspecified',
                      }"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showTenseList"
              >
                <div class="morph-box" tabindex="0" id="Tense">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="tenseChecked"
                        id="switch-Tense"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            tenseChecked,
                            'chosenTense',
                            'baseformTenseList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Tense</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Tense')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{ 'text-primary': isFlagged('table', 'Tense') }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values">
                    <b-form-checkbox-group
                      stacked
                      v-model="chosenTense"
                      :options="baseformTenseList"
                      @change="
                        tenseChecked = chosenTense && chosenTense.length > 0
                      "
                      :class="{ disabled: !tenseChecked }"
                    ></b-form-checkbox-group>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showNounTypeList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="nounTypeChecked"
                        id="switch-NounType"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            nounTypeChecked,
                            'chosenNounType',
                            'baseformNounTypeList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Noun Type</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'NounType')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'NounType'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="nounTypeChecked = true">
                    <b-form-select
                      v-model="chosenNounType"
                      id="NounType"
                      :options="baseformNounTypeList"
                      :class="{
                        disabled:
                          !nounTypeChecked || chosenNounType === 'Unspecified',
                      }"
                      @change="
                        setFirstLast('chosenNounType', 'baseformNounTypeList')
                      "
                      @input="nounTypeChecked = true"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showConjunctionList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        id="switch-Conjunction"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            conjunctionChecked,
                            'chosenConjunction',
                            'baseformConjunctionTypeList'
                          )
                        "
                        v-model="conjunctionChecked"
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Conjunction</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Conjunction')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Conjunction'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="conjunctionChecked = true">
                    <b-form-select
                      v-model="chosenConjunction"
                      id="Conjunction"
                      :options="baseformConjunctionTypeList"
                      :class="{
                        disabled:
                          !conjunctionChecked ||
                          chosenConjunction === 'Unspecified',
                      }"
                      @change="
                        setFirstLast(
                          'chosenConjunction',
                          'baseformConjunctionTypeList'
                        )
                      "
                      @input="conjunctionChecked = true"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showPrefixList"
              >
                <div
                  class="morph-box"
                  tabindex="0"
                  id="Prefixes"
                  ref="prefixes"
                >
                  <div
                    class="morph-name bg-background position-relative position-relative"
                  >
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="prefixChecked"
                        id="switch-Prefixes"
                        tabindex="-1"
                        switch
                      >
                        <!--@change="setFirstChecked(prefixChecked, 'chosenPrefix', 'baseformPrefixList')"-->
                      </b-form-checkbox>
                    </div>
                    <span>Prefixes</span>
                    <input
                      type="number"
                      ref="prefixInput"
                      v-model="prefixLength"
                      :class="{ 'border-primary': prefixChecked }"
                      @change="setPrefixLength(prefixLength)"
                      min="0"
                      :max="
                        currentWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/).length < 5
                          ? currentWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/).length
                          : 5
                      "
                      class="text-center prefix-len rounded ml-2"
                    />
                    <div
                      class="position-absolute"
                      :class="{ 'text-primary': prefixChecked }"
                      @click="togglePrefixLock"
                      style="right: 40px; top: 5px"
                    >
                      <i v-if="lockPrefixes" class="fas fa-lock"></i>
                      <i v-else class="fas fa-lock-open"></i>
                    </div>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Prefixes')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Prefixes'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values">
                    <b-form-checkbox-group
                      stacked
                      v-model="chosenPrefix"
                      :options="baseformPrefixList"
                      :disabled="lockPrefixes"
                      @change="prefixChecked = true"
                      :class="{ disabled: !prefixChecked || lockPrefixes }"
                    ></b-form-checkbox-group>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showSuffixList"
              >
                <div class="morph-box" tabindex="0" id="Suffixes">
                  <div
                    class="morph-name bg-background position-relative position-relative"
                  >
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="suffixChecked"
                        id="switch-Suffixes"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            suffixChecked,
                            'chosenSuffix',
                            'suffixList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Suffixes</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Suffixes')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Suffixes'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values suffixes" ref="suffixGroup">
                    <b-form-radio-group
                      v-model="chosenSuffix"
                      stacked
                      :class="{
                        disabled:
                          !suffixChecked || chosenSuffix === 'Unspecified',
                      }"
                      @change="setSuffixInner(chosenSuffix)"
                      ref="suffixListGroup"
                    >
                      <b-form-radio
                        v-for="(suffixListItem, mli) in suffixList"
                        :key="mli"
                        stacked
                        :id="suffixList[mli]"
                        :class="{
                          disabled: !suffixChecked,
                          'd-none':
                            decreaseSuffixes &&
                            mli > 0 &&
                            suffixList[mli] !== 'Pronominal',
                        }"
                        :value="suffixListItem"
                      >
                        <span>{{ suffixListItem }}</span>
                        <span v-if="mli === 1 || suffixList.length === 2">
                          <span
                            class="text-muted d-inline-block ml-2"
                            v-if="checkSuffixSubVal()"
                            >[</span
                          >
                          <span
                            class="text-muted d-inline-block"
                            v-if="
                              chosenSuffixType !== 'Unspecified' &&
                              chosenSuffixType !== ''
                            "
                            >{{ chosenSuffixType }}...</span
                          >
                          <span
                            class="text-muted d-inline-block"
                            v-if="
                              chosenSuffixNum !== 'Unspecified' &&
                              chosenSuffixNum !== ''
                            "
                            >{{ chosenSuffixNum }}...</span
                          >
                          <span
                            class="text-muted d-inline-block"
                            v-if="
                              chosenSuffixGender !== 'Unspecified' &&
                              chosenSuffixGender !== ''
                            "
                            >{{ chosenSuffixGender }}...</span
                          >
                          <span
                            class="text-muted d-inline-block"
                            v-if="
                              chosenSuffixPerson !== 'Unspecified' &&
                              chosenSuffixPerson !== ''
                            "
                            >{{ chosenSuffixPerson }}...</span
                          >
                          <span
                            class="text-muted d-inline-block"
                            v-if="checkSuffixSubVal()"
                            >]</span
                          >
                          <span
                            class="position-absolute right-arrow"
                            @click="showSuffixSubmenu = !showSuffixSubmenu"
                          >
                            <i
                              v-if="showSuffixSubmenu"
                              class="fas fa-caret-down"
                            ></i>
                            <i v-else class="fas fa-caret-up"></i>
                          </span>
                        </span>
                        <div
                          class="row bg-white"
                          v-if="
                            showSuffixSubmenu &&
                            (mli === 1 || suffixList.length === 2)
                          "
                        >
                          <div class="sub-heading mt-1 pl-4 w-100 text-muted">
                            Type
                          </div>
                          <b-form-radio-group
                            class="submenu"
                            stacked
                            v-model="chosenSuffixType"
                            @change="subSuffixClicked()"
                            :options="baseformSuffixTypeList"
                            :select-size="baseformSuffixTypeList.length"
                          >
                          </b-form-radio-group>
                          <div class="sub-heading w-100 pl-4 text-muted">
                            Number
                          </div>
                          <b-form-radio-group
                            class="submenu"
                            stacked
                            v-model="chosenSuffixNum"
                            @change="subSuffixClicked()"
                            :options="baseformSuffixNumList"
                            :select-size="baseformSuffixNumList.length"
                          >
                          </b-form-radio-group>
                          <div class="sub-heading w-100 pl-4 text-muted">
                            Person
                          </div>
                          <b-form-radio-group
                            class="submenu"
                            stacked
                            v-model="chosenSuffixPerson"
                            @change="subSuffixClicked()"
                            :options="baseformSuffixPersonList"
                            :select-size="baseformSuffixPersonList.length"
                          >
                          </b-form-radio-group>
                          <div class="sub-heading w-100 pl-4 text-muted">
                            Gender
                          </div>
                          <b-form-radio-group
                            class="submenu"
                            stacked
                            v-model="chosenSuffixGender"
                            @change="subSuffixClicked()"
                            :options="baseformSuffixGenderList"
                            :select-size="baseformSuffixGenderList.length"
                          >
                          </b-form-radio-group>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </div>
              </div>

              <!--positivity-->
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showPositivityList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="positivityChecked"
                        id="switch-Positivity"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            positivityChecked,
                            'chosenPositivity',
                            'baseformPositivityList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Positivity</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Positivity')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Positivity'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="positivityChecked = true">
                    <b-form-select
                      v-model="chosenPositivity"
                      id="Positivity"
                      :options="baseformPositivityList"
                      :class="{
                        disabled:
                          !positivityChecked ||
                          chosenPositivity === 'Unspecified',
                      }"
                      @change="
                        setFirstLast(
                          'chosenPositivity',
                          'baseformPositivityList'
                        )
                      "
                      @input="positivityChecked = true"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <!-- status for non noun-->
              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="
                  displayMorphObject.showStatusList &&
                  chosenSpeechPart !== 'Noun'
                "
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="statusChecked"
                        id="switch-Status"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            statusChecked,
                            'chosenStatus',
                            'baseformStatusList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Status</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Status')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Status'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="statusChecked = true">
                    <b-form-select
                      v-model="chosenStatus"
                      id="Status"
                      :options="baseformStatusList"
                      @change="
                        setFirstLast('chosenStatus', 'baseformStatusList')
                      "
                      @input="statusChecked = true"
                      :class="{
                        disabled:
                          !statusChecked || chosenStatus === 'Unspecified',
                      }"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showDetTypeList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="detTypeChecked"
                        id="switch-Det-Type"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            detTypeChecked,
                            'chosenDetType',
                            'baseformDetTypeList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Det Type</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Det-Type')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Det-Type'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="detTypeChecked = true">
                    <b-form-select
                      v-model="chosenDetType"
                      id="Det-Type"
                      :options="baseformDetTypeList"
                      :class="{
                        disabled:
                          !detTypeChecked || chosenDetType === 'Unspecified',
                      }"
                      @change="
                        setFirstLast('chosenDetType', 'baseformDetTypeList')
                      "
                      @input="detTypeChecked = true"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 mb-4"
                v-if="displayMorphObject.showDetDefList"
              >
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <b-form-checkbox
                        v-model="detDefChecked"
                        id="switch-Det-Def"
                        tabindex="-1"
                        @change="
                          setFirstChecked(
                            detDefChecked,
                            'chosenDetDef',
                            'baseformDetDefList'
                          )
                        "
                        switch
                      >
                      </b-form-checkbox>
                    </div>
                    <span>Det Def</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Det-Def')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Det-Def'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="morph-values" @click="detDefChecked = true">
                    <b-form-select
                      v-model="chosenDetDef"
                      id="Det-Def"
                      :options="baseformDetDefList"
                      :class="{
                        disabled:
                          !detDefChecked || chosenDetDef === 'Unspecified',
                      }"
                      @change="
                        setFirstLast('chosenDetDef', 'baseformDetDefList')
                      "
                      @input="detDefChecked = true"
                      :select-size="6"
                    >
                    </b-form-select>
                  </div>
                </div>
              </div>

              <!--properties-->
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="morph-box">
                  <div class="morph-name bg-background position-relative">
                    <div class="d-inline-block">
                      <!--    <b-form-checkbox 
                      v-model="passiveChecked" 
                      id="switch-Passive"
                      tabindex="-1"
                      @change="setFirstChecked(passiveChecked, 'chosenPassivity', 'baseformPassiveList')"
                      switch>
                    </b-form-checkbox> -->
                    </div>
                    <span>Properties</span>
                    <div
                      class="position-absolute"
                      @click="setFlag('table', 'Properties')"
                    >
                      <i
                        aria-hidden="true"
                        :class="{
                          'text-primary': isFlagged('table', 'Properties'),
                        }"
                        class="fa fa-flag mr-1"
                      ></i>
                    </div>
                  </div>
                  <div class="px-3 py-2 morph-values overflow-auto">
                    <b-form-checkbox
                      v-model="chosenNominalized"
                      value="Nominalized"
                      unchecked-value="Unspecified"
                      id="switch-Nominalized"
                      tabindex="-1"
                      switch
                    >
                      Nominalized
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="NominalizedAdjective"
                      unchecked-value="Unspecified"
                      v-model="chosenNominalizedAdjective"
                      id="switch-nominalizedAdjective"
                      tabindex="-1"
                      switch
                    >
                      Nominalized Adjective
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="NomConstruct"
                      unchecked-value="Unspecified"
                      v-model="chosenNomConstruct"
                      id="switch-NomConstruct"
                      tabindex="-1"
                      switch
                    >
                      Nom Construct
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="chosenContinued"
                      value="Continued"
                      unchecked-value="Unspecified"
                      id="switch-Continued"
                      tabindex="-1"
                      switch
                    >
                      Continued
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="Passive"
                      unchecked-value="Unspecified"
                      v-model="chosenPassivity"
                      id="switch-Passive"
                      tabindex="-1"
                      switch
                    >
                      Passivity
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="EnablesInfinitive"
                      unchecked-value="Unspecified"
                      v-model="chosenEnablesInfinitive"
                      id="switch-EnablesInfinitive"
                      tabindex="-1"
                      switch
                    >
                      EnablesInfinitive
                    </b-form-checkbox>

                    <b-form-checkbox
                      value="Hagam"
                      unchecked-value="Unspecified"
                      v-model="chosenHagam"
                      id="switch-Hagam"
                      tabindex="-1"
                      switch
                    >
                      Hagam
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="RegressivePrefixLetter"
                      unchecked-value="Unspecified"
                      v-model="chosenRegressivePrefixLetter"
                      id="switch-RegressivePrefixLetter"
                      tabindex="-1"
                      switch
                    >
                      RegressivePrefixLetter
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="Arabic"
                      unchecked-value="Unspecified"
                      v-model="chosenArabic"
                      id="switch-Arabic"
                      tabindex="-1"
                      switch
                    >
                      Arabic
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="Foreign"
                      unchecked-value="Unspecified"
                      v-model="chosenForeign"
                      id="switch-Foreign"
                      tabindex="-1"
                      switch
                    >
                      Foreign
                    </b-form-checkbox>
                    <b-form-checkbox
                      value="NikudInaccurate"
                      unchecked-value="Unspecified"
                      v-model="chosenNikudInaccurate"
                      id="switch-nikudinaccurate"
                      tabindex="-1"
                      switch
                    >
                      Nikud Inaccurate
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginNeededPopup v-model="showLoginPopup" @cancelled="submit" />
    </b-modal>
  </div>
</template>

<script>
//TODO: prefix setup, tabs, flags
const DEF_STAT = ["ב", "כ", "ל"]
const baseformPrefixListInitial = PREFIX_FUNCTION_NUM_TO_STR
import _ from "lodash"
import LoginNeededPopup from "@/components/LoginNeededPopup.vue"
import prefixMixins from "@/mixins/prefixMixins"
import { stringWithoutNikud } from "shared/js/commonHebrew"

import {
  BASEFORM_POS_NUM_TO_STR,
  BASEFORM_PERSON_NUM_TO_STR,
  BASEFORM_GENDER_NUM_TO_STR,
  BASEFORM_STATUS_NUM_TO_STR,
  BASEFORM_NUMBER_NUM_TO_STR,
  BASEFORM_TENSE_NUM_TO_STR,
  PREFIX_FUNCTION_NUM_TO_STR,
  SUFFIX_FUNCTION_NUM_TO_STR,
  SUFFIX_TYPE_NUM_TO_STR,
  SUFFIX_PERSON_NUM_TO_STR,
  SUFFIX_NUMBER_NUM_TO_STR,
  SUFFIX_GENDER_NUM_TO_STR,
  BASEFORM_NOUNTYPE_NUM_TO_STR,
  BASEFORM_POSITIVITY_NUM_TO_STR,
  BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR,
  BASEFORM_DETTYPE_NUM_TO_STR,
  BASEFORM_DETDEF_NUM_TO_STR,
  BASEFORM_PASSIVE_NUM_TO_STR,
} from "@/js/morphologies"

export default {
  name: "MorphologyPopup",
  components: { LoginNeededPopup },
  data() {
    return {
      lockPrefixes: true,
      decreaseSuffixes: false,
      showLoginPopup: false,
      searchString: [],
      lastWord: false,
      lexEnabled: true,
      // tabbedElement: null,
      prefixLength: 0,
      posChecked: false,
      genderChecked: false,
      numberChecked: false,
      personChecked: false,
      statusChecked: false,
      tenseChecked: false,
      prefixChecked: false,
      suffixChecked: false,
      nounTypeChecked: false,
      positivityChecked: false,
      /*     passiveChecked: false,
          nominalizedChecked: false,
          continuedChecked: false, */
      conjunctionChecked: false,
      detTypeChecked: false,
      detDefChecked: false,
      chosenSpeechPart: "",
      chosenGender: "",
      chosenNumber: "",
      chosenPerson: [],
      chosenStatus: "",
      chosenTense: [],
      chosenPrefix: [],
      chosenSuffix: "",
      chosenSuffixType: "",
      chosenSuffixNum: "",
      chosenSuffixPerson: "",
      chosenSuffixGender: "",
      chosenNounType: "",
      chosenConjunction: "",
      chosenPositivity: "",
      chosenPassivity: "",
      chosenNominalized: "",
      chosenNominalizedAdjective: "",
      chosenNomConstruct: "",
      chosenContinued: "",
      chosenEnablesInfinitive: "",
      chosenHagam: "",
      chosenRegressivePrefixLetter: "",
      chosenArabic: "",
      chosenForeign: "",
      chosenNikudInaccurate: "",
      chosenDetType: "",
      chosenDetDef: "",
      baseformPosList: BASEFORM_POS_NUM_TO_STR,
      baseformGenderList: BASEFORM_GENDER_NUM_TO_STR,
      baseformNumberList: BASEFORM_NUMBER_NUM_TO_STR,
      baseformPersonList: BASEFORM_PERSON_NUM_TO_STR,
      baseformStatusList: BASEFORM_STATUS_NUM_TO_STR,
      baseformTenseList: BASEFORM_TENSE_NUM_TO_STR,

      suffixList: SUFFIX_FUNCTION_NUM_TO_STR,
      baseformSuffixNumList: SUFFIX_NUMBER_NUM_TO_STR,
      baseformSuffixTypeList: SUFFIX_TYPE_NUM_TO_STR,
      baseformSuffixPersonList: SUFFIX_PERSON_NUM_TO_STR,
      baseformSuffixGenderList: SUFFIX_GENDER_NUM_TO_STR,
      baseformNounTypeList: BASEFORM_NOUNTYPE_NUM_TO_STR,
      baseformPositivityList: BASEFORM_POSITIVITY_NUM_TO_STR,
      baseformConjunctionTypeList: BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR,
      baseformDetTypeList: BASEFORM_DETTYPE_NUM_TO_STR,
      baseformDetDefList: BASEFORM_DETDEF_NUM_TO_STR,
      baseformPassiveList: BASEFORM_PASSIVE_NUM_TO_STR,
      morphStringArr: [],
      newMorphOption: {},
      showSuffixSubmenu: true,
      displayMorphObject: {
        showGenderList: false,
        showNumberList: false,
        showPersonList: false,
        showStatusList: false,
        showTenseList: false,
        showPrefixList: false,
        showSuffixList: false,
        showNounTypeList: false,
        showConjunctionList: false,
        showPositivityList: false,
        showDetTypeList: false,
        showDetDefList: false,
      },
    }
  },
  mixins: [prefixMixins],
  props: [
    "value",
    "currentMorphObject",
    "newMorph",
    "groupRange",
    "editMode",
    "canDelete",
  ],
  computed: {
    keymap() {
      return {
        up: () => {
          if (this.show) {
            this.prevRow()
          }
        },
        down: () => {
          if (this.show) {
            this.nextRow(event)
          }
        },
        right: () => {
          if (this.show) {
            this.setNextActiveList(event)
          }
        },
        left: () => {
          if (this.show) {
            this.setPrevActiveList(event)
          }
        },
        "shift+1": () => {
          if (this.show) {
            this.toggleTable()
          }
        },
        "shift+/": () => {
          if (this.show) {
            var activeEl = document.activeElement.id
            if (
              document.activeElement.classList.contains("custom-control-input")
            ) {
              activeEl = document.activeElement.closest(".morph-box").id
            }
            this.setFlag("table", activeEl)
          }
        },
        "shift+]": () => {
          this.setPrefixLength("plus")
        },
        "shift+[": () => {
          this.setPrefixLength("minus")
        },
        enter: () => {
          if (this.show) this.submit()
        },
        "shift+2": () => {
          if (this.show) this.setPropernameMorph("Masculine")
        },
        "shift+3": () => {
          if (this.show) this.setPropernameMorph("Feminine")
        },
        "shift+4": () => {
          if (this.show) this.setPropernameMorph("Masculinefeminine")
        },
      }
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    baseformPrefixList() {
      let prefixLastLetter = this.currentWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/)[
        this.prefixLength - 1
      ]
      if (
        this.prefixLength > 0 &&
        DEF_STAT.indexOf(stringWithoutNikud(prefixLastLetter)) < 0
      ) {
        return baseformPrefixListInitial.filter((element) => {
          return element !== "Pref_Definite_Status"
        })
      } else {
        return baseformPrefixListInitial
      }
    },
    lexOptions() {
      let arr = ["לא ידוע"]
      this.currentMorphData[this.currentWordIndex].options.map((item) => {
        if (arr.indexOf(item.word) < 0 && item.word !== "") arr.push(item.word)
      })
      return arr
    },
    currentLex() {
      return this.newMorphOption.word
        ? this.newMorphOption.word
        : this.lexOptions[0]
    },
    currentWordIndex() {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    currentMorphIndex() {
      return this.currentMorphData[this.currentWordIndex].selctedMorphIndex
    },
    currentWord() {
      return this.currentMorphData[this.currentWordIndex].groupId
        ? this.currentMorphData[
            this.currentMorphData[this.currentWordIndex].groupId
          ].text
        : this.currentMorphData[this.currentWordIndex].text
    },
    currentMorphData() {
      return this.$store.state.morphologyApp.morphData
    },
    currentMorphArray() {
      return this.newMorphOption.morphOptions
    },
    userLoggedIn() {
      return this.$store.state.account && this.$store.state.account.userData
    },
    hideLoginPopup() {
      return this.$store.state.hideLoginPopup
    },
    currentWordObj() {
      return this.currentMorphData[this.currentWordIndex]
    },
    currentMorphOption() {
      return (
        this.currentWordObj.selectedMorph ??
        this.currentWordObj.options[this.currentMorphIndex]
      )
    },
    cannotDeleteMorph: {
      get() {
        return !this.$store.state.canDelete
      },
      set() {
        this.$store.state.canDelete = true
      },
    },
  },
  watch: {
    userLoggedIn(val) {
      if (val) {
        this.submit()
      }
    },
  },
  created() {
    window.onpopstate = function () {
      if (document.getElementById("cancel-btn")) {
        document.getElementById("cancel-btn").click()
      }
    }
  },
  methods: {
    deleteMorphology() {
      if (this.groupRange.length > 0) {
        this.$store.commit("REMOVE_GROUP_MORPHOLOGY", {
          contextData: {
            morph: this.currentMorphObject,
            morphIndex: this.currentMorphObject.optionIndex,
          },
        })
      } else {
        this.$store.commit("REMOVE_MORPHOLOGY", {
          contextData: {
            morph: this.currentMorphObject,
            morphIndex: this.currentMorphObject.optionIndex,
          },
        })
      }
      if (!this.cannotDeleteMorph) {
        this.show = false
      }
    },
    togglePrefixLock() {
      this.lockPrefixes = !this.lockPrefixes
    },
    subSuffixClicked() {
      this.suffixChecked = true
      this.chosenSuffix = "Pronominal"
    },
    setSuffixInner(suffixValue) {
      this.suffixChecked = true
      if (suffixValue !== "Pronominal") {
        //clear sub radio suffix groups
        this.chosenSuffixType = "Unspecified"
        this.chosenSuffixGender = "Unspecified"
        this.chosenSuffixNum = "Unspecified"
        this.chosenSuffixPerson = "Unspecified"
      }
    },
    setFirstLast(currentValue, currentList) {
      if (this[currentValue] === "Unspecified") {
        let listLastIndex = this[currentList].length - 2
        this[currentValue] = this[currentList][listLastIndex]
      }
      if (this[currentValue] === "---") {
        this[currentValue] = this[currentList][1]
      }
      if (currentList === "baseformPosList") this.displayMorphs()
    },
    getSearchString() {
      let hNum = this.currentWordIndex + 26
      let lNum = this.currentWordIndex
      lNum -= 10
      if (lNum < 0) lNum = 0
      if (hNum > this.currentMorphData.length) {
        hNum = this.currentMorphData.length
        lNum = this.currentMorphData.length - 26
        this.lastWord = true
      }
      this.searchString =
        this.currentMorphData.length < 15
          ? this.currentMorphData
          : this.currentMorphData.slice(lNum, hNum)
    },
    setLex() {
      this.lexEnabled = !this.lexEnabled
      this.newMorphOption.lexEnabled = this.lexEnabled
    },
    setLexValue(lexIndex) {
      this.lexEnabled = lexIndex > 0
      this.newMorphOption.lexEnabled = this.lexEnabled
      this.newMorphOption.word = this.lexOptions[lexIndex]
    },
    modalShow() {
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      )
      this.setCurrentFormValues()
      this.getSearchString()
    },
    modalHide() {
      this.$router.go(-1)
    },
    checkSuffixSubVal() {
      ////return this.chosenSuffixType !== 'NONE' || this.chosenSuffixNum !== 'NONE' || this.chosenSuffixPerson !== 'NONE' || this.chosenSuffixGender != 'NONE'
      return (
        this.chosenSuffixType !== "Unspecified" ||
        this.chosenSuffixNum !== "Unspecified" ||
        this.chosenSuffixPerson !== "Unspecified" ||
        this.chosenSuffixGender != "Unspecified"
      )
    },
    setFirstChecked(tableOn, currentTable, currentList) {
      /*       if(currentTable === 'chosenPrefix') {
              if(tableOn) {
                this.prefixLength = 0
              }
            } */
      if (!tableOn) {
        if (this[currentTable]) {
          if (Array.isArray(this[currentTable])) {
            this[currentTable] = [this[currentList][1]]
          } else {
            this[currentTable] = this[currentList][1]
          }
        }
      }
    },
    toggleTable() {
      let tableId = document.activeElement.id
      if (
        document.activeElement.classList.contains("custom-control-input") &&
        !tableId.includes("switch")
      ) {
        tableId = document.activeElement.closest(".morph-box").id
      }
      if (tableId.includes("switch")) {
        tableId = tableId.split("switch-")[1]
      }
      switch (tableId) {
        case "Gender":
          this.genderChecked = !this.genderChecked
          break
        case "Part-of-speech":
          this.posChecked = !this.posChecked
          break
        case "Number":
          this.numberChecked = !this.numberChecked
          break
        case "Person":
          this.personChecked = !this.personChecked
          break
        case "Status":
          this.statusChecked = !this.statusChecked
          break
        case "Tense":
          this.tenseChecked = !this.tenseChecked
          break
        case "NounType":
          this.nounTypeChecked = !this.nounTypeChecked
          break
        case "Conjunction":
          this.conjunctionChecked = !this.conjunctionChecked
          break
        case "Positivity":
          this.positivityChecked = !this.positivityChecked
          break
        /*  case 'Passive':
           this.passiveChecked = !this.passiveChecked
           break; */
        case "Det-Type":
          this.detTypeChecked = !this.detTypeChecked
          break
        case "Det-Def":
          this.detDefChecked = !this.detDefChecked
          break
        case "Prefixes":
          this.prefixChecked = !this.prefixChecked
          //if (!this.prefixChecked)
          //  this.prefixLength = 0
          break
        case "Suffixes":
          this.suffixChecked = !this.suffixChecked
          break
      }
    },
    prevRow() {
      if (document.activeElement.classList.contains("custom-control-input")) {
        window.event.preventDefault()
        if (document.activeElement.id === "Suffix_other") {
          //go into last submenu last item
          let submenuList = document.activeElement
            .closest(".morph-box")
            .querySelectorAll(".submenu")
          let lastSubmenu = submenuList[submenuList.length - 1]
          let radioList = lastSubmenu.querySelectorAll(".custom-radio")
          radioList[radioList.length - 1].querySelector("input").focus()
        } else if (document.activeElement.id === "Suffix_Prepositional") {
          //on first radio - to to last
          document.getElementById("Suffix_other").focus()
          document.getElementById("Suffix_other").click()
        } else {
          let prevCheckboxDiv =
            document.activeElement.parentElement.previousElementSibling
          if (prevCheckboxDiv) {
            if (
              prevCheckboxDiv.querySelector("input").value !== "Unspecified"
            ) {
              prevCheckboxDiv.querySelector("input").focus()
              if (
                prevCheckboxDiv.querySelector("input").type === "radio" &&
                !prevCheckboxDiv.closest(".submenu")
              ) {
                prevCheckboxDiv.querySelector("input").click() //click on radio if not subitem
              }
            } else {
              // go to last checkbox or radio
              let checkboxesList = prevCheckboxDiv
                .closest(".morph-box")
                .querySelectorAll(".custom-checkbox ")
              if (checkboxesList.length) {
                checkboxesList[checkboxesList.length - 1]
                  .querySelector("input")
                  .focus()
              } else {
                //go to previous radio sub group
                var subRadioGroup =
                  prevCheckboxDiv.closest(".submenu").previousElementSibling
                if (
                  subRadioGroup &&
                  subRadioGroup.classList.contains("sub-heading")
                ) {
                  subRadioGroup = subRadioGroup.previousElementSibling
                  if (subRadioGroup) {
                    //go to last item of previous group
                    let radioList =
                      subRadioGroup.querySelectorAll(".custom-radio")
                    if (radioList && radioList.length > 0) {
                      radioList[radioList.length - 1]
                        .querySelector("input")
                        .focus()
                    }
                  } else {
                    //reached first sub group - no previous subgroup focus on pronominal
                    document.getElementById("Pronominal").focus()
                    document.getElementById("Pronominal").click()
                  }
                }
              }
            }
          }
        }
      }
    },
    nextRow(event) {
      if (document.activeElement.classList.contains("morph-box")) {
        if (document.activeElement.id === "Suffixes") {
          event.preventDefault()
          event.stopPropagation()
        }
        //if suffix list and suffix already selected focus on it
        if (
          this.chosenSuffix !== "Unspecified" &&
          document.activeElement.id === "Suffixes"
        ) {
          document.getElementById(this.chosenSuffix).focus()
        } else {
          let focusRing =
            document.activeElement.querySelectorAll(".bv-no-focus-ring ")[0]
          let checkboxesList =
            document.activeElement.querySelectorAll(".custom-checkbox")
          let firstCheckbox = checkboxesList[1]
          let radioList =
            document.activeElement.querySelectorAll(".custom-radio")
          var firstRadio = radioList[1]
          if (firstRadio && firstRadio.classList.contains("d-none"))
            firstRadio = radioList[3] //if only displaying Pronominal and we are in suffixes
          if (focusRing) {
            focusRing.focus()
            focusRing.classList.remove("disabled")
            if (firstCheckbox) {
              //prevent parent div from scrolling
              event.stopPropagation()
              event.preventDefault()
              firstCheckbox.querySelector("input").focus()
            } else if (firstRadio) {
              firstRadio.querySelector("input").focus()
              firstRadio.querySelector("input").click()
            }
          }
        }
      } else if (
        document.activeElement.classList.contains("custom-control-input")
      ) {
        window.event.preventDefault()
        if (document.activeElement.value === "Pronominal") {
          document.activeElement.parentElement
            .querySelectorAll(".bv-no-focus-ring ")[0]
            .focus()
          let innerRadio =
            document.activeElement.querySelectorAll(".custom-radio")[1]
          if (innerRadio) {
            innerRadio.querySelector("input").focus()
            //innerRadio.querySelector('input').click()
          }
        } else {
          let nextCheckboxDiv =
            document.activeElement.parentElement.nextElementSibling
          if (nextCheckboxDiv) {
            nextCheckboxDiv.querySelector("input").focus()
            if (nextCheckboxDiv.querySelector("input").type === "radio") {
              nextCheckboxDiv.querySelector("input").focus()
              if (!document.activeElement.closest(".submenu"))
                //if not with radio button visible
                nextCheckboxDiv.querySelector("input").click()
            }
          } else if (document.activeElement.closest(".submenu")) {
            let nextRadioGroup =
              document.activeElement.closest(".submenu").nextElementSibling
            if (
              nextRadioGroup &&
              nextRadioGroup.classList.contains("sub-heading")
            ) {
              nextRadioGroup = nextRadioGroup.nextElementSibling
            }
            if (nextRadioGroup) {
              let innerRadio =
                nextRadioGroup.querySelectorAll(".custom-radio")[1]
              if (innerRadio) {
                innerRadio.querySelector("input").focus()
                //innerRadio.querySelector('input').click()
              }
            } else {
              //we reached last radio input in submenu
              //go to parent radio next sibling
              let lastRadio = document.activeElement
                .closest(".submenu")
                .closest(".custom-radio").nextElementSibling
              if (lastRadio) {
                lastRadio.querySelector("input").focus()
                lastRadio.querySelector("input").click()
              }
            }
          } else {
            //if checkbox list go to first available checkbox or radio in list
            let firstControl = document.activeElement
              .closest(".morph-box")
              .querySelectorAll(".custom-control-input")[2]
            if (firstControl) firstControl.focus()
            if (firstControl.type === "radio") firstControl.click()
          }
        }
      }
    },
    setPrevActiveList(event) {
      event.stopPropagation()
      let element = document.activeElement
      if (
        document.activeElement.classList.contains("custom-control-input") ||
        element.type === "number"
      ) {
        if (document.activeElement.type === "radio") {
          event.preventDefault()
        }
        element = document.activeElement.closest(".morph-box")
      }
      if (
        element.classList.contains("morph-box") ||
        element.classList.contains("custom-select")
      ) {
        if (element.id === "Part-of-speech") {
          let rightTables = document
            .getElementById("right-side-tables")
            .querySelectorAll(".col-lg-4")
          if (rightTables.length > 0) {
            element = document
              .getElementById("right-side-tables")
              .querySelectorAll(".col-lg-4")[rightTables.length - 1]
            let findSelect = element.querySelectorAll(".custom-select")
            if (findSelect.length > 0) {
              findSelect[0].focus()
            } else {
              let morphBox = element.querySelectorAll(".morph-box")
              if (morphBox.length > 0) {
                morphBox[0].focus()
              }
            }
          }
        } else {
          let prevWrap = element.closest(".col-lg-4").previousElementSibling
          if (!prevWrap) {
            document.getElementById("Part-of-speech").focus()
          } else {
            let findSelect = prevWrap.querySelectorAll(".custom-select")
            if (findSelect.length > 0) {
              findSelect[0].focus()
            } else {
              let morphBox = element
                .closest(".col-lg-4")
                .previousElementSibling.querySelectorAll(".morph-box")
              if (morphBox.length > 0) morphBox[0].focus()
            }
          }
        }
      }
    },
    setNextActiveList(event) {
      event.stopPropagation()
      let element = document.activeElement
      if (
        element.classList.contains("custom-control-input") ||
        element.type === "number"
      ) {
        element = document.activeElement.closest(".morph-box")
        if (element.type === "radio") {
          event.preventDefault()
          document.getElementById("Part-of-speech").focus()
        }
      }
      if (
        element.classList.contains("morph-box") ||
        element.classList.contains("custom-select")
      ) {
        if (element.id === "Part-of-speech") {
          element = document
            .getElementById("right-side-tables")
            .querySelectorAll(".col-lg-4")[0]
          let findSelect = element.querySelectorAll(".custom-select")
          if (findSelect.length > 0) {
            findSelect[0].focus()
          } else {
            let morphBox = element.querySelectorAll(".morph-box")
            if (morphBox.length > 0) {
              morphBox[0].focus()
            }
          }
        } else {
          let nextWrap = element.closest(".col-lg-4").nextElementSibling
          if (!nextWrap) {
            document.getElementById("Part-of-speech").focus()
          } else {
            let findSelect = nextWrap.querySelectorAll(".custom-select")
            if (findSelect.length > 0) {
              findSelect[0].focus()
            } else {
              let morphBox = element
                .closest(".col-lg-4")
                .nextElementSibling.querySelectorAll(".morph-box")
              if (morphBox.length > 0) morphBox[0].focus()
            }
          }
        }
      }
    },
    setPrefixLength(actionOrNum) {
      document.activeElement.blur()
      this.$refs.prefixInput.focus()
      if (actionOrNum == "plus") {
        if (
          this.prefixLength < 5 &&
          this.prefixLength < this.currentWord.length
        )
          this.prefixLength++
      } else if (actionOrNum == "minus") {
        if (this.prefixLength > 0) this.prefixLength--
      } else {
        this.newMorphOption.returnedWord =
          this.newMorphOption.returnedWord.replace("|", "")
        this.prefixLength = actionOrNum
      }
      if (this.prefixLength == 0 && this.lockPrefixes) {
        this.chosenPrefix = ["Unspecified"]
        this.prefixChecked = false
      } else {
        let wordNoNikud = this.newMorphOption.returnedWord.replace(
          /[^א-ת]/g,
          ""
        )
        let prefix = wordNoNikud.substring(0, this.prefixLength)
        if (this.lockPrefixes) this.chosenPrefix = this.setPrefix(prefix)
        if (this.prefixLength > 0) {
          this.prefixChecked = true
        }
      }
      //  this.$store.commit("SET_PREFIX", this.prefixLength)
    },
    difference(object, base) {
      // TODO: Remove after testing
      function changes(object, base) {
        return _.transform(object, function (result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value
          }
        })
      }
      return changes(object, base)
    },
    displayMorphs(firstLoad) {
      this.posChecked = true
      this.decreaseSuffixes = false
      this.baseformPersonList = BASEFORM_PERSON_NUM_TO_STR
      this.baseformSuffixTypeList = SUFFIX_TYPE_NUM_TO_STR
      this.displayMorphObject.showGenderList = false
      this.displayMorphObject.showNumberList = false
      this.displayMorphObject.showPersonList = false
      this.displayMorphObject.showStatusList = false
      this.displayMorphObject.showTenseList = false
      this.displayMorphObject.showPrefixList = true
      this.displayMorphObject.showSuffixList = false
      this.displayMorphObject.showNounTypeList = false
      this.displayMorphObject.showConjunctionList = false
      this.displayMorphObject.showDetTypeList = false
      this.displayMorphObject.showDetDefList = false
      this.displayMorphObject.showPositivityList = false
      switch (this.chosenSpeechPart.toUpperCase()) {
        case "POS_UNKNOWN":
        case "SHEL_PREP":
        case "AT_PREP":
        case "NEGATION":
          this.displayMorphObject.showSuffixList = true
          break
        case "INTERROGATIVE":
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showGenderList = true
          break
        case "ADVERB":
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showStatusList = true
          break
        case "BAREINFINITIVE":
        case "TOINFINITIVE":
          this.displayMorphObject.showSuffixList = true
          this.decreaseSuffixes = true
          break
        case "PREPOSITION":
          this.displayMorphObject.showSuffixList = true
          this.decreaseSuffixes = true
          break
        case "NOUN":
          this.displayMorphObject.showNounTypeList = true
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showStatusList = true
          break
        case "PROPERNAME":
          this.displayMorphObject.showNounTypeList = true
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showStatusList = true
          //this.decreaseSuffixes = true
          this.baseformSuffixTypeList = [
            "Unspecified",
            "Possessive",
            "Nominative",
          ]
          break
        case "ADJECTIVE":
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showStatusList = true
          break
        case "PRONOUN":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          break
        case "MODAL":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showTenseList = true
          this.displayMorphObject.showSuffixList = true
          break
        case "VERB":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showTenseList = true
          this.displayMorphObject.showSuffixList = true
          break
        case "PARTICIPLE":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showStatusList = true
          this.displayMorphObject.showSuffixList = true
          break
        case "POS_CONJUNCTION":
          this.displayMorphObject.showConjunctionList = true
          break
        case "POS_NUMBER":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showStatusList = true
          this.displayMorphObject.showSuffixList = true
          this.decreaseSuffixes = true
          break
        case "EXISTENTIAL":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showTenseList = true
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showPositivityList = true
          this.decreaseSuffixes = true
          this.baseformPersonList = ["Unspecified", "Person_3"]
          break
        case "COPULA":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showTenseList = true
          this.displayMorphObject.showStatusList = true
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showPositivityList = true
          break
        case "BMODAL":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showTenseList = true
          this.displayMorphObject.showPositivityList = true
          break
        case "INTERJECTION":
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showPositivityList = true
          break
        case "DET_QUANTIFIER":
          this.displayMorphObject.showGenderList = true
          this.displayMorphObject.showNumberList = true
          this.displayMorphObject.showPersonList = true
          this.displayMorphObject.showSuffixList = true
          this.displayMorphObject.showStatusList = true
          this.displayMorphObject.showDetTypeList = true
          this.displayMorphObject.showDetDefList = true
          break
        case "PUNCTUATION":
        case "CONTINUE":
          this.displayMorphObject.showPrefixList = false
          break
      }
      if (firstLoad) {
        this.statusChecked =
          this.chosenStatus !== "Unspecified" &&
          this.chosenStatus !== "" &&
          this.displayMorphObject.showStatusList
        this.genderChecked =
          this.chosenGender !== "Unspecified" &&
          this.chosenGender !== "" &&
          this.displayMorphObject.showGenderList
        this.numberChecked =
          this.chosenNumber !== "Unspecified" &&
          this.chosenNumber !== "" &&
          this.displayMorphObject.showNumberList
        this.personChecked =
          this.chosenPerson.length > 1 ||
          (this.chosenPerson.length == 1 &&
            this.chosenPerson[0] !== "Unspecified" &&
            this.displayMorphObject.showPersonList)
        this.tenseChecked =
          this.chosenTense.length > 1 ||
          (this.chosenTense.length === 1 &&
            this.chosenTense[0] !== "Unspecified" &&
            this.displayMorphObject.showTenseList)
        this.prefixChecked =
          this.chosenPrefix.length > 1 ||
          (this.chosenPrefix.length === 1 &&
            this.chosenPrefix[0] !== "Unspecified" &&
            this.displayMorphObject.showPrefixList)
        this.suffixChecked =
          this.chosenSuffix !== "Unspecified" && this.chosenSuffix !== ""
        this.nounTypeChecked =
          this.chosenNounType !== "Unspecified" &&
          this.chosenNounType !== "" &&
          this.displayMorphObject.showNounTypeList
        this.conjunctionChecked =
          this.chosenConjunction !== "Unspecified" &&
          this.chosenConjunction !== "" &&
          this.displayMorphObject.showConjunctionList
        this.positivityChecked =
          this.chosenPositivity !== "Unspecified" &&
          this.chosenPositivity !== "" &&
          this.displayMorphObject.showPositivityList
        /*  this.passiveChecked = this.chosenPassivity !== 'Unspecified' && this.chosenPassivity !== ''
         this.nominalizedChecked = this.chosenNominalized !== 'Unspecified' && this.chosenNominalized !== ''
         this.continuedChecked = this.chosenContinued !== 'Unspecified' && this.chosenContinued !== '' */
        this.detTypeChecked =
          this.chosenDetType !== "Unspecified" &&
          this.chosenDetType !== "" &&
          this.displayMorphObject.showDetTypeList
        this.detDefChecked =
          this.chosenDetDef !== "Unspecified" &&
          this.chosenDetDef !== "" &&
          this.displayMorphObject.showDetDefList
      }
    },
    reset() {
      this.chosenConjunction = "Unspecified"
      this.chosenDetDef = "Unspecified"
      this.chosenDetType = "Unspecified"
      this.chosenGender = "Unspecified"
      this.chosenNounType = "Unspecified"
      this.chosenNumber = "Unspecified"
      this.chosenPerson = ["Unspecified"]
      this.chosenPositivity = "Unspecified"
      this.chosenPassivity = "Unspecified"
      this.chosenStatus = "Unspecified"
      this.chosenSuffix = "Unspecified"
      this.chosenSuffixGender = "Unspecified"
      this.chosenSuffixPerson = "Unspecified"
      this.chosenSuffixType = "Unspecified"
      this.chosenPrefix = ["Unspecified"]
      this.chosenTense = ["Unspecified"]
    },
    close() {
      this.setCurrentFormValues()
      this.cannotDeleteMorph = false
      this.show = false
    },
    setFlag(morphItem, value) {
      if (!this.isFlagged(morphItem, value)) {
        if (!this.newMorphOption.flag) {
          this.newMorphOption.flag = []
        }
        this.newMorphOption.flag.push({ flag: morphItem + ": " + value })
      } else {
        var index = this.newMorphOption.flag.indexOf({
          flag: morphItem + ": " + value,
        })
        this.newMorphOption.flag.splice(index, 1)
      }
    },
    isFlagged(morphItem, value) {
      let flagVal = { flag: morphItem + ": " + value }
      let flagArr = this.newMorphOption.flag
      return flagArr
        ? flagArr.some((flagItem) => flagItem.flag === flagVal.flag)
        : false
    },
    checkLogin() {
      if (!this.userLoggedIn && !this.hideLoginPopup) {
        //(!this.userLoggedIn && sessionStorage.getItem("morphology-login-displayed") == null) ||
        this.showLoginPopup = true
      } else {
        this.submit()
      }
    },
    submit() {
      if (this.show) {
        if (this.currentMorphObject.pinned && !this.editMode) {
          // if duplicating remove pin from new morph
          //this.$store.commit("UPDATE_USER_DICTIONARY", {
          //contextData: { morphObj: this.currentMorphObject, operation: 'removeNoMessage'} }
          //)
          delete this.newMorphOption.pinned
          delete this.newMorphOption.indexForUnpin
        }
        delete this.newMorphOption.indexForRestore
        if (this.posChecked)
          this.newMorphOption.morphOptions.partOfSpeech = this.chosenSpeechPart
        if (this.genderChecked && this.displayMorphObject.showGenderList)
          this.newMorphOption.morphOptions.gender = this.chosenGender
        else this.newMorphOption.morphOptions.gender = "Unspecified"
        if (this.numberChecked && this.displayMorphObject.showNumberList)
          this.newMorphOption.morphOptions.number = this.chosenNumber
        else this.newMorphOption.morphOptions.number = "Unspecified"
        if (this.personChecked && this.displayMorphObject.showPersonList)
          this.newMorphOption.morphOptions.person = this.chosenPerson
        else this.newMorphOption.morphOptions.person = ["Unspecified"]
        if (this.prefixChecked && this.displayMorphObject.showPrefixList) {
          this.newMorphOption.morphOptions.prefixes = this.chosenPrefix
        } else this.newMorphOption.morphOptions.prefixes = ["Unspecified"]
        if (this.statusChecked && this.displayMorphObject.showStatusList)
          this.newMorphOption.morphOptions.status = this.chosenStatus
        else this.newMorphOption.morphOptions.status = "Unspecified"
        if (this.tenseChecked && this.displayMorphObject.showTenseList)
          this.newMorphOption.morphOptions.tense = this.chosenTense
        else this.newMorphOption.morphOptions.tense = ["Unspecified"]
        if (this.suffixChecked && this.displayMorphObject.showSuffixList) {
          this.newMorphOption.morphOptions.suffix = this.chosenSuffix
          this.newMorphOption.morphOptions.suffixNumber = this.chosenSuffixNum
          this.newMorphOption.morphOptions.suffixType = this.chosenSuffixType
          this.newMorphOption.morphOptions.suffixPerson =
            this.chosenSuffixPerson
          this.newMorphOption.morphOptions.suffixGender =
            this.chosenSuffixGender
        } else {
          this.newMorphOption.morphOptions.suffix = "Unspecified"
          this.newMorphOption.morphOptions.suffixNumber = "Unspecified"
          this.newMorphOption.morphOptions.suffixType = "Unspecified"
          this.newMorphOption.morphOptions.suffixPerson = "Unspecified"
          this.newMorphOption.morphOptions.suffixGender = "Unspecified"
        }
        if (this.nounTypeChecked && this.displayMorphObject.showNounTypeList)
          this.newMorphOption.morphOptions.nounType = this.chosenNounType
        else this.newMorphOption.morphOptions.nounType = "Unspecified"

        this.newMorphOption.morphOptions.passivity = this.chosenPassivity
        this.newMorphOption.morphOptions.nominalized = this.chosenNominalized
        this.newMorphOption.morphOptions.nominalizedAdjective =
          this.chosenNominalizedAdjective
        this.newMorphOption.morphOptions.nomConstruct = this.chosenNomConstruct
        this.newMorphOption.morphOptions.continued = this.chosenContinued
        this.newMorphOption.morphOptions.enablesInfinitive =
          this.chosenEnablesInfinitive
        this.newMorphOption.morphOptions.hagam = this.chosenHagam
        this.newMorphOption.morphOptions.regressivePrefixLetter =
          this.chosenRegressivePrefixLetter
        this.newMorphOption.morphOptions.arabic = this.chosenArabic
        this.newMorphOption.morphOptions.foreign = this.chosenForeign
        this.newMorphOption.morphOptions.nikudInaccurate =
          this.chosenNikudInaccurate
        if (
          this.conjunctionChecked &&
          this.displayMorphObject.showConjunctionList
        )
          this.newMorphOption.morphOptions.conjunctionType =
            this.chosenConjunction
        else this.newMorphOption.morphOptions.conjunctionType = "Unspecified"
        if (this.detTypeChecked && this.displayMorphObject.showDetTypeList)
          this.newMorphOption.morphOptions.detType = this.chosenDetType
        else this.newMorphOption.morphOptions.detType = "Unspecified"
        if (this.detDefChecked && this.displayMorphObject.showDetDefList)
          this.newMorphOption.morphOptions.detDef = this.chosenDetDef
        else this.newMorphOption.morphOptions.detDef = "Unspecified"
        this.newMorphOption.fromServer = this.currentMorphObject.fromServer
        this.newMorphOption.prefixLength = this.prefixLength
        //if morph changed create or edit...
        if (!_.isEqual(this.newMorphOption, this.currentMorphObject)) {
          if (this.editMode) {
            //overwrite
            if (this.userLoggedIn) {
              this.$store.commit("UPDATE_USER_DICTIONARY", {
                contextData: {
                  morphObj: this.currentMorphObject,
                  operation: "removeNoMessage",
                },
              })
            }
            if (this.groupRange.length > 0) {
              this.$store.commit("SET_GROUP_MORPH_OPTION", this.newMorphOption)
              this.$store.commit(
                "SET_GROUP_SELECTED_MORPH",
                this.newMorphOption
              )
            } else {
              this.$store.commit("EDIT_MORPH_OPTION", {
                contextData: {
                  morph: this.currentMorphObject,
                  newMorph: this.newMorphOption,
                },
              })
              this.$store.commit("SET_SELECTED_MORPH", this.newMorphOption)
            }
            if (this.userLoggedIn) {
              this.$store.commit("UPDATE_USER_DICTIONARY", {
                contextData: {
                  morphObj: this.newMorphOption,
                  operation: "edit",
                },
              })
            }
          } else {
            //this.difference(this.newMorphOption, this.currentMorphObject)
            //TODO newMorph by default true, set in MorphologyTable.vue. False if exisiting option editable
            this.newMorphOption.fromServer = false
            //this.newMorphOption.word = '' //?
            if (this.groupRange && this.groupRange.length > 0) {
              this.$store.commit("SET_CURRENT_GROUP_MORPH_INDEX", 0)
              let newWord = ""
              this.groupRange.forEach((element) => {
                if (!this.currentMorphData[element].sep)
                  newWord += this.currentMorphData[element].text + "_"
              })
              this.newMorphOption.returnedWord = newWord.slice(0, -1)
              this.$store.commit(
                "SET_GROUP_SELECTED_MORPH",
                this.newMorphOption
              )
              this.$store.commit("ADD_GROUP_MORPH_OPTION", this.newMorphOption)
            } else {
              this.$store.commit("ADD_MORPH_OPTION", this.newMorphOption)
              this.$store.commit("SET_SELECTED_MORPH", this.newMorphOption)
              //this.$store.commit("SET_CURRENT_MORPH_INDEX", 1)
            }
            if (this.userLoggedIn) {
              this.$store.commit("UPDATE_USER_DICTIONARY", {
                contextData: {
                  morphObj: this.newMorphOption,
                  operation: "add",
                },
              })
            }
          }
          this.$emit("morph-updated")
        }
        this.show = false
      }
    },
    setCurrentFormValues() {
      this.newMorphOption = _.cloneDeep(this.currentMorphObject)
      this.prefixLength = this.newMorphOption.prefixLength
      this.chosenSpeechPart = this.newMorphOption.morphOptions.partOfSpeech
      this.chosenGender = this.newMorphOption.morphOptions.gender
      this.chosenNumber = this.newMorphOption.morphOptions.number
      this.chosenPerson = this.newMorphOption.morphOptions.person
      this.chosenStatus = this.newMorphOption.morphOptions.status
      this.chosenTense = this.newMorphOption.morphOptions.tense
      this.chosenPrefix = this.newMorphOption.morphOptions.prefixes
      this.chosenSuffix = this.newMorphOption.morphOptions.suffix
      this.chosenSuffixType = this.newMorphOption.morphOptions.suffixType
      this.chosenSuffixNum = this.newMorphOption.morphOptions.suffixNumber
      this.chosenSuffixPerson = this.newMorphOption.morphOptions.suffixPerson
      this.chosenSuffixGender = this.newMorphOption.morphOptions.suffixGender
      this.chosenNounType = this.newMorphOption.morphOptions.nounType
      this.chosenConjunction = this.newMorphOption.morphOptions.conjunctionType
      this.chosenPositivity = this.newMorphOption.morphOptions.positivity
      /*       if (this.chosenPositivity === undefined)
        this.chosenPositivity = 'Unspecified' */
      this.chosenPassivity = this.newMorphOption.morphOptions.passivity
      this.chosenNominalized =
        this.newMorphOption?.morphOptions?.nominalized ?? "Unspecified"

      this.chosenNominalizedAdjective =
        this.newMorphOption?.morphOptions?.nominalizedAdjective ?? "Unspecified"

      this.chosenNomConstruct =
        this.newMorphOption?.morphOptions?.nomConstruct ?? "Unspecified"

      this.chosenContinued =
        this.newMorphOption?.morphOptions?.continued ?? "Unspecified"

      this.chosenEnablesInfinitive =
        this.newMorphOption?.morphOptions?.enablesInfinitive ?? "Unspecified"

      this.chosenHagam =
        this.newMorphOption?.morphOptions?.hagam ?? "Unspecified"

      this.chosenRegressivePrefixLetter =
        this.newMorphOption?.morphOptions?.regressivePrefixLetter ??
        "Unspecified"

      this.chosenArabic =
        this.newMorphOption?.morphOptions?.arabic ?? "Unspecified"

      this.chosenForeign =
        this.newMorphOption?.morphOptions?.foreign ?? "Unspecified"

      this.chosenNikudInaccurate =
        this.newMorphOption?.morphOptions?.nikudInaccurate ?? "Unspecified"

      this.chosenDetType = this.newMorphOption.morphOptions.detType
      this.chosenDetDef = this.newMorphOption.morphOptions.detDef
      this.lexEnabled = this.newMorphOption.lexEnabled
      this.displayMorphs(true)
    },
    setPropernameMorph(gender) {
      /* A shortcut to help creating propername morph options. This will set the chosen morph to
        ProperName + {gender} + Singular + NounType_person + Absolute,
        and will leave the prefixes, suffixes, and properties tables without change */
      this.chosenConjunction = ""
      this.chosenDetDef = ""
      this.chosenDetType = ""
      this.chosenGender = gender
      this.chosenNounType = "Nountype_person"
      this.chosenNumber = "Singular"
      this.chosenPerson = []
      this.chosenPositivity = ""
      this.chosenSpeechPart = "Propername"
      this.chosenStatus = "Absolute"
      this.chosenTense = []

      this.posChecked = false
      this.genderChecked = true
      this.numberChecked = true
      this.personChecked = false
      this.statusChecked = true
      this.tenseChecked = false
      this.nounTypeChecked = true
      this.positivityChecked = false
      this.conjunctionChecked = false
      this.detTypeChecked = false
      this.detDefChecked = false

      /* If the current word is not part of a group *and* the currently selected
          morph option is a proper name, use its lexeme (if it exists) */
      if (
        this.currentWordObj.groupRange.length === 0 &&
        !["", "לא ידוע"].includes(this.currentMorphOption.word) &&
        this.currentMorphOption?.morphOptions.partOfSpeech === "Propername"
      ) {
        this.newMorphOption.word = this.currentMorphOption.word
        this.lexEnabled = true
        this.newMorphOption.lexEnabled = true
      } else {
        this.newMorphOption.word = "לא ידוע"
        this.lexEnabled = false
        this.newMorphOption.lexEnabled = false
      }

      this.displayMorphs(false)
    },
  },
}
</script>

<style lang="scss">
#morph-popup {
  .dropdown-menu {
    top: 30px !important;
    transform: none !important;
    max-height: 300px;
    overflow-y: scroll;
    left: auto !important;
    right: 0;
    width: 100%;
    padding-top: 3px;

    li {
      .dropdown-item {
        padding: 2px 10px 2px 0;
        font-family: "Arimo";

        &.active {
          color: black !important;
          background-color: #d6ecff !important;
          position: relative;

          &::after {
            content: "\F00c";
            font-family: "Font Awesome 5 Free";
            font-size: 12px;
            position: absolute;
            left: 10px;
            top: 5px;
            font-weight: 900;
          }
        }
      }
    }

    li:not(:first-of-type) {
      .dropdown-item {
        padding: 0 10px 0 0;
        font-size: 20px !important;
        font-family: "mft_narkisclassic";
      }
    }
  }

  .d-dropdown {
    border-radius: 4px;
    height: 28px;

    button {
      min-width: 163px;
      width: 100%;
      text-align: right;
      border-radius: 4px;
      font-size: 20px;
      font-family: "mft_narkisclassic";
      height: 28px;
      line-height: 28px;
      padding: 0 10px;

      &::after {
        border-top: 0.35em solid;
        border-right: 0.3em solid transparent;
        border-left: 0.3em solid transparent;
        position: absolute;
        left: 5px;
        top: 9px;
      }
    }

    &.current-unk {
      button {
        font-size: 16px;
        font-family: arimo;
      }
    }
  }
}

body.modal-open #morph-popup select option {
  -webkit-text-fill-color: black;
}

body.modal-open #morph-popup select:disabled option {
  -webkit-text-fill-color: #6c757d;
}

body.modal-open #morph-popup select.disabled option {
  -webkit-text-fill-color: #6c757d;
}

body.modal-open #morph-popup select option::after {
  -webkit-text-fill-color: white;
}

#morph-popup {
  .modal-content {
    overflow-y: auto;
    height: 100vh;
  }

  .container {
    width: 1280px;
    max-width: 90%;
  }

  padding: 0 !important;
  margin: 0;

  .modal-xl {
    max-width: 100%;
    padding: 0;
    margin: 0;

    .modal-header {
      background: #f6f6f6;

      h2 {
        font-size: 23px;
      }

      .btn-link:hover {
        background: none;
      }
    }
  }
}

#morph-popup .morph-box {
  &:focus {
    outline: none !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 0 0.2rem rgba(0, 126, 229, 0.25);
  }

  .custom-control:not(.custom-switch):not(.disabled) {
    &:hover {
      background: #f6f6f6;

      .right-arrow {
        background: #f6f6f6;
      }
    }
  }

  .disabled {
    .custom-control:not(.custom-switch) {
      &:hover {
        background: #fff;
      }
    }

    select {
      option {
        &:hover {
          background: #fff;
          cursor: auto;
        }
      }
    }
  }

  .custom-radio {
    .custom-control-label {
      width: 100%;
      padding: 2px 15px;
    }

    .custom-control-input:checked ~ .custom-control-label {
      background: #d6ecff;

      .right-arrow {
        background: #d6ecff;
      }
    }

    .custom-control-label:before,
    .custom-control-label:after {
      display: none;
    }
  }

  .submenu {
    .custom-control-input:checked ~ .custom-control-label {
      background: white;

      &:hover {
        background: #f6f6f6;
      }
    }

    .custom-radio {
      padding-left: 3rem;

      label {
        padding: 2px;
      }
    }

    width: 100%;

    .custom-control-label:before,
    .custom-control-label:after {
      display: block;
    }
  }

  //disable he class changes
  .custom-checkbox {
    margin-top: 4px;
    padding-left: 2.5rem;
  }

  .custom-checkbox,
  .custom-radio {
    padding-right: 0;

    &:first-child {
      display: none;
    }

    .custom-control-label:before,
    .custom-control-label:after {
      left: -1.5rem;
      right: auto;
    }
  }

  .custom-switch {
    padding-left: 2.25rem !important;
    padding-right: 0 !important;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    -webkit-transform: translateX(0.75rem) !important;
    transform: translateX(0.75rem) !important;
  }

  .custom-switch .custom-control-label:before {
    left: -2.25rem;
    right: 0 !important;
  }

  .custom-select[size]:not([size="1"]) {
    height: 100% !important;
  }

  .custom-select {
    border: none !important;
    font-size: 16px;
    overflow-y: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;

    &:disabled,
    .disabled {
      background: white;

      option {
        cursor: auto;

        &:hover {
          background: #fff;
        }
      }
    }

    option {
      padding: 3px 15px;
      height: 27px;
      line-height: 1.5rem;

      &:first-child,
      &:last-child {
        height: 0;
        padding: 0;
        overflow: hidden;
        font-size: 0;
      }

      cursor: pointer;

      &:checked {
        background: #d6ecff -webkit-linear-gradient(bottom, #d6ecff 0%, #d6ecff
              100%);

        &:hover {
          background: #d6ecff -webkit-linear-gradient(bottom, #d6ecff 0%, #d6ecff
                100%);
        }
      }

      &:hover {
        background: #f6f6f6;
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.close-btn {
  cursor: pointer;
  font-size: 28px;
  line-height: 10px;
  height: 10px;
  top: 2px;
  color: #949494;
}

.prefix-wrap {
  .active {
    background: #d6ecff;
    min-width: 5px;
    display: inline-block;
  }

  .selected-text {
    font-size: 20px;
    height: 35px;
    padding-top: 3px;
    overflow: hidden;

    .prefix-sep {
      right: -2px;
    }
  }

  /*   .prefix-len-wrap {
      padding-top: 2px;      
    } */
  .lex-enable {
    padding-top: 1px;
    border-left: 1px solid #d8d8d8;
  }
}

.prefix-len {
  width: 30px;
  font-size: 13px;
  border: 1px solid #d8d8d8;
}

.morph-box {
  border: 1px solid black;
  border-radius: 4px;

  .morph-values {
    height: 175px;

    .right-arrow {
      width: 30px;
      text-align: center;
      z-index: 1;
      background: white;
      right: 0;
      top: 2px;
    }

    div.bv-no-focus-ring[role="radiogroup"] {
      height: auto;
      padding-top: 5px;
      overflow-x: hidden;

      &.disabled {
        opacity: 0.6;
      }
    }

    div.bv-no-focus-ring[role="group"] {
      height: 175px;
      overflow-y: scroll;

      &.disabled {
        opacity: 0.6;
      }
    }

    &.suffixes {
      overflow-y: scroll;
    }
  }

  .morph-name {
    padding: 5px 15px;
    border-bottom: 1px solid black;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    &.morph-properties {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .position-absolute {
      top: 6px;
      right: 10px;
      color: rgba(74, 80, 87, 0.5);
      cursor: pointer;
    }
  }

  &.pos {
    .morph-values {
      height: auto;
      overflow-y: inherit;
    }
  }

  .back {
    margin: 8px 0 1px;
    cursor: pointer;
    color: #969595;

    i {
      margin: 0 3px 0 13px;
      color: #808080;
    }
  }
}
</style>
