/* eslint-disable no-prototype-builtins */

const FULLFORM_ARAMAIC = 0x4000000000000000n
const PREFIX_LENGTH = 0x7n
const BASEFORM_PASSIVE = 0x40000000000n
const BASEFORM_PASSIVE_TRUE = 0x40000000000n
// Prefix
// Function
// bits 4-11 (9 bits)
const PREFIX_FUNCTION_CONJUNCTION = 0x8n
const PREFIX_FUNCTION_DEFINITEARTICLE = 0x10n
const PREFIX_FUNCTION_INTERROGATIVE = 0x20n
const PREFIX_FUNCTION_PREPOSITIONAL = 0x40n
const PREFIX_FUNCTION_RELATIVIZER_SUBORDINATING = 0x80n
const PREFIX_FUNCTION_TEMPORAL = 0x100n
const PREFIX_FUNCTION_EMPHATIC = 0x200n
const PREFIX_FUNCTION_VUVINVERSIVE = 0x20000000000n
const PREFIX_DEFINITESTATUS_DEFINITE = 0x800n
const PREFIX_FUNCTION_OTHER = 0x400n

//const PREFIX_MASK = 0x7F8n

// POS
// bits 14-19 (6 bits, for 33 options [uncertain])
const BASEFORM_POS = 0x7E000n
const BASEFORM_POS_ABBREVIATION = 0x2000n
const BASEFORM_POS_ADJECTIVE = 0x4000n
const BASEFORM_POS_ADVERB = 0x6000n
const BASEFORM_POS_CITATION = 0x8000n
const BASEFORM_POS_REF = 0x46000n
const BASEFORM_POS_COMPOUND = 0xa000n
const BASEFORM_POS_CONJUNCTION = 0xc000n
const BASEFORM_POS_CONTINUE = 0xe000n
const BASEFORM_POS_COPULA = 0x10000n
const BASEFORM_POS_DET_QUANTIFIER = 0x12000n
const BASEFORM_POS_EXISTENTIAL = 0x14000n
const BASEFORM_POS_FOREIGN = 0x16000n
const BASEFORM_POS_INTERJECTION = 0x18000n
const BASEFORM_POS_INTERROGATIVE = 0x1a000n
const BASEFORM_POS_JUNK = 0x1c000n
const BASEFORM_POS_MODAL = 0x1e000n
const BASEFORM_POS_NEGATION = 0x20000n
const BASEFORM_POS_NONSTANDARD = 0x22000n
const BASEFORM_POS_NOUN = 0x24000n
const BASEFORM_POS_NUMBER = 0x26000n
const BASEFORM_POS_PARTICIPLE = 0x28000n
const BASEFORM_POS_PARTICLE = 0x2a000n
const BASEFORM_POS_PREFIX = 0x2c000n
const BASEFORM_POS_PREPOSITION = 0x2e000n
const BASEFORM_POS_AT_PREP = 0x30000n
const BASEFORM_POS_SHEL_PREP = 0x32000n
const BASEFORM_POS_PRONOUN = 0x34000n
const BASEFORM_POS_PROPERNAME = 0x36000n
const BASEFORM_POS_PUNCTUATION = 0x38000n
const BASEFORM_POS_SUFFIX = 0x3a000n
const BASEFORM_POS_UNKNOWN = 0x3c000n
const BASEFORM_POS_URL = 0x3e000n
const BASEFORM_POS_VERB = 0x40000n
const BASEFORM_POS_TOINFINITIVE = 0x42000n
const BASEFORM_POS_BAREINFINITIVE = 0x44000n
// .. we have another 30 options here: 0x46000n .... 0x7e000n

// Gender
// 4 options (+ unspecified)
//bits 20-22
const BASEFORM_GENDER = 0x380000n
//const BASEFORM_GENDER_NONE = 0x80000n
const BASEFORM_GENDER_MASCULINE = 0x100000n
const BASEFORM_GENDER_FEMININE = 0x200000n
const BASEFORM_GENDER_MASCULINEFEMININE = 0x300000n

// Number
// bits 23-25
const BASEFORM_NUMBER = 0x1C00000n
const BASEFORM_NUMBER_SINGULAR = 0x400000n
const BASEFORM_NUMBER_PLURAL = 0x800000n
const BASEFORM_NUMBER_DUAL = 0x1000000n
const BASEFORM_NUMBER_SINGULARPLURAL = 0xC00000n
const BASEFORM_NUMBER_DUALPLURAL = 0x1800000n
//const BASEFORM_NUMBER_NONE = 0x1C00000n

// Person
// bits 26-28
const BASEFORM_PERSON = 0xE000000n
const BASEFORM_PERSON_1 = 0x2000000n
const BASEFORM_PERSON_2 = 0x4000000n
const BASEFORM_PERSON_3 = 0x8000000n
const BASEFORM_PERSON_ANY = 0xE000000n

// Tense
// bits 29-34
const BASEFORM_TENSE = 0x3F0000000n
const BASEFORM_TENSE_PAST = 0x10000000n
const BASEFORM_TENSE_PRESENT = 0x20000000n
const BASEFORM_TENSE_FUTURE = 0x40000000n
const BASEFORM_TENSE_IMPERATIVE = 0x80000000n
const BASEFORM_TENSE_OTHER = 0x100000000n
//const BASEFORM_TENSE_NONE = 0x200000000n


// bits 35-37
// Status
const BASEFORM_STATUS = 0x1C00000000n
const BASEFORM_STATUS_ABSOLUTE = 0x400000000n
const BASEFORM_STATUS_CONSTRUCT = 0x800000000n
const BASEFORM_STATUS_ABSOLUTECONSTRUCT = 0xC00000000n

// Suffix
// Function
// bits 50-51
const SUFFIX_FUNCTION_PRONOMINAL = 0x2000000000000n
const SUFFIX_FUNCTION_PREPOSITIONAL = 0x4000000000000n
const SUFFIX_FUNCTION_DEFINITEARTICLE = 0x6000000000000n
const SUFFIX_FUNCTION = 0x6000000000000n

// Suffix type (pronominal only)
// bits 52-53  Accusative, Possessive, Nominative
const SUFFIX_TYPE_ACCUSATIVE = 0x8000000000000n
const SUFFIX_TYPE_POSSESSIVE = 0x10000000000000n
const SUFFIX_TYPE_NOMINATIVE = 0x18000000000000n
const SUFFIX_TYPE = 0x18000000000000n

// Suffix Gender (pronominal only)
// bits 54-55
const SUFFIX_GENDER_MASCULINE = 0x20000000000000n
const SUFFIX_GENDER_FEMININE = 0x40000000000000n
const SUFFIX_GENDER_MASCULINEFEMININE = 0x60000000000000n
const SUFFIX_GENDER = 0x60000000000000n

// Suffix Number
// bits 56-57
const SUFFIX_NUMBER_SINGULAR = 0x80000000000000n
const SUFFIX_NUMBER_PLURAL = 0x100000000000000n
const SUFFIX_NUMBER = 0x180000000000000n

// Suffix Person
// bits 58-60
const SUFFIX_PERSON_1 = 0x200000000000000n
const SUFFIX_PERSON_2 = 0x400000000000000n
const SUFFIX_PERSON_3 = 0x800000000000000n
const SUFFIX_PERSON_ANY = 0xE00000000000000n
const SUFFIX_PERSON = 0xE00000000000000n

// NounType
// bits 38-41

const BASEFORM_NOUNTYPE = 0x1E000000000n
const BASEFORM_NOUNTYPE_PLACE = 0x2000000000n
const BASEFORM_NOUNTYPE_PERSON = 0x4000000000n
const BASEFORM_NOUNTYPE_BOOK_COMPOSITION = 0x6000000000n
const BASEFORM_NOUNTYPE_PERSON_BOOK = 0x8000000000n
const BASEFORM_NOUNTYPE_EVENT_HOLIDAY = 0xa000000000n
const BASEFORM_NOUNTYPE_HONORIFIC = 0xc000000000n
const BASEFORM_NOUNTYPE_YEAR = 0xe000000000n
const BASEFORM_NOUNTYPE_OTHER = 0x10000000000n

// Positivity
// bit 42-43
//const BASEFORM_POSITIVITY = 0x60000000000n
//const BASEFORM_POSITIVITY_POSITIVE = 0x20000000000n
//const BASEFORM_POSITIVITY_NEGATIVE = 0x40000000000n
//const BASEFORM_POSITIVITY_NONE = 0x60000000000n

//Conjunction type
// bit 44-45
const BASEFORM_CONJUNCTIONTYPE = 0x180000000000n
const BASEFORM_CONJUNCTIONTYPE_COORDINATING = 0x80000000000n
const BASEFORM_CONJUNCTIONTYPE_SUBORDINATING = 0x100000000000n

// DetType
// bits 46-47
const BASEFORM_DETTYPE = 0x600000000000n
const BASEFORM_DETTYPE_AMOUNT = 0x200000000000n
const BASEFORM_DETTYPE_PARTITIVE = 0x400000000000n
const BASEFORM_DETTYPE_DETERMINER = 0x600000000000n

// DetDef:
// bits 48-49
const BASEFORM_DETDEF = 0x1800000000000n
const BASEFORM_DETDEF_DEFINITE = 0x800000000000n
const BASEFORM_DETDEF_INDEFINITE = 0x1000000000000n

const BASEFORM_NOUNTYPE_LIST = [0n, BASEFORM_NOUNTYPE_PLACE, BASEFORM_NOUNTYPE_PERSON, BASEFORM_NOUNTYPE_BOOK_COMPOSITION,
  BASEFORM_NOUNTYPE_PERSON_BOOK, BASEFORM_NOUNTYPE_EVENT_HOLIDAY, BASEFORM_NOUNTYPE_HONORIFIC,
  BASEFORM_NOUNTYPE_YEAR, BASEFORM_NOUNTYPE_OTHER]
const BASEFORM_NOUNTYPE_NUM_TO_STR = ['Unspecified', 'Place', 'Nountype_person', 'Book_composition',
  'Person_book', 'Event_holiday', 'Honorific',
  'Year', 'Nountype_other', '---']
//const BASEFORM_POSITIVITY_LIST = [0n, BASEFORM_POSITIVITY_POSITIVE, BASEFORM_POSITIVITY_NEGATIVE]
const BASEFORM_POSITIVITY_NUM_TO_STR = ['Unspecified', 'Positivity_positive', 'Positivity_negative', '---']
const BASEFORM_CONJUNCTIONTYPE_LIST = [0n, BASEFORM_CONJUNCTIONTYPE_COORDINATING, BASEFORM_CONJUNCTIONTYPE_SUBORDINATING]
const BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR = ['Unspecified', 'Coordinating', 'Subordinating', '---']
const BASEFORM_DETTYPE_LIST = [0n, BASEFORM_DETTYPE_AMOUNT, BASEFORM_DETTYPE_PARTITIVE, BASEFORM_DETTYPE_DETERMINER]
const BASEFORM_DETTYPE_NUM_TO_STR = ['Unspecified', 'Amount', 'Partitive', 'Determiner', '---']
const BASEFORM_DETDEF_LIST = [0n, BASEFORM_DETDEF_DEFINITE, BASEFORM_DETDEF_INDEFINITE]
const BASEFORM_DETDEF_NUM_TO_STR = ['Unspecified', 'Definite', 'Indefinite', '---']

const PREFIX_FUNCTION_LIST = [0n, PREFIX_FUNCTION_CONJUNCTION,
  PREFIX_FUNCTION_PREPOSITIONAL, PREFIX_FUNCTION_DEFINITEARTICLE,
  PREFIX_DEFINITESTATUS_DEFINITE, PREFIX_FUNCTION_INTERROGATIVE,
  PREFIX_FUNCTION_RELATIVIZER_SUBORDINATING, PREFIX_FUNCTION_TEMPORAL,
  PREFIX_FUNCTION_VUVINVERSIVE, PREFIX_FUNCTION_EMPHATIC, PREFIX_FUNCTION_OTHER]
const PREFIX_FUNCTION_NUM_TO_STR = ["Unspecified", "Pref_conjunction", "Pref_Prepositional",
  "Pref_definitearticle", "Pref_Definite_Status",
  "Pref_interrogative", "Relativizer_Subordinating",
  "Pref_Temporal", "Vuvinversive", "Pref_Emphatic", "Pref_other"]
const SUFFIX_FUNCTION_LIST = [0n, SUFFIX_FUNCTION_PRONOMINAL, SUFFIX_FUNCTION_PREPOSITIONAL, SUFFIX_FUNCTION_DEFINITEARTICLE]
const SUFFIX_FUNCTION_NUM_TO_STR = ["Unspecified", "Pronominal", "Suffix_Prepositional", "Suffix_Definitearticle", "Suffix_other"]
const SUFFIX_TYPE_LIST = [0n, SUFFIX_TYPE_ACCUSATIVE, SUFFIX_TYPE_POSSESSIVE, SUFFIX_TYPE_NOMINATIVE]
const SUFFIX_TYPE_NUM_TO_STR = ["Unspecified", "Accusative", "Possessive", "Nominative"]
const SUFFIX_GENDER_LIST = [0n, SUFFIX_GENDER_MASCULINE, SUFFIX_GENDER_FEMININE, SUFFIX_GENDER_MASCULINEFEMININE]
const SUFFIX_GENDER_NUM_TO_STR = ['Unspecified', 'Suf_Masculine', 'Suf_Feminine', 'Suf_Masculinefeminine']
const SUFFIX_NUMBER_LIST = [0n, SUFFIX_NUMBER_SINGULAR, SUFFIX_NUMBER_PLURAL]
const SUFFIX_NUMBER_NUM_TO_STR = ['Unspecified', 'Suf_Singular', 'Suf_Plural']
const SUFFIX_PERSON_LIST = [0n, SUFFIX_PERSON_1, SUFFIX_PERSON_2, SUFFIX_PERSON_3, SUFFIX_PERSON_ANY]
const SUFFIX_PERSON_NUM_TO_STR = ['Unspecified', 'Suf_Person_1', 'Suf_Person_2', 'Suf_Person_3', 'Suf_Person_Any']

const BASEFORM_POS_LIST = [0n, BASEFORM_POS_ABBREVIATION, BASEFORM_POS_ADJECTIVE, BASEFORM_POS_ADVERB, BASEFORM_POS_CITATION, BASEFORM_POS_REF,
  BASEFORM_POS_COMPOUND, BASEFORM_POS_CONJUNCTION, BASEFORM_POS_CONTINUE, BASEFORM_POS_COPULA, BASEFORM_POS_DET_QUANTIFIER,
  BASEFORM_POS_EXISTENTIAL, BASEFORM_POS_FOREIGN, BASEFORM_POS_INTERJECTION, BASEFORM_POS_INTERROGATIVE, BASEFORM_POS_JUNK,
  BASEFORM_POS_MODAL, BASEFORM_POS_NEGATION, BASEFORM_POS_NONSTANDARD, BASEFORM_POS_NOUN, BASEFORM_POS_NUMBER,
  BASEFORM_POS_PARTICIPLE, BASEFORM_POS_PARTICLE, BASEFORM_POS_PREFIX, BASEFORM_POS_PREPOSITION, BASEFORM_POS_AT_PREP,
  BASEFORM_POS_SHEL_PREP, BASEFORM_POS_PRONOUN, BASEFORM_POS_PROPERNAME, BASEFORM_POS_PUNCTUATION, BASEFORM_POS_SUFFIX,
  BASEFORM_POS_UNKNOWN, BASEFORM_POS_URL, BASEFORM_POS_VERB, BASEFORM_POS_TOINFINITIVE, BASEFORM_POS_BAREINFINITIVE
]
const BASEFORM_POS_NUM_TO_STR = [
  "Unspecified", "Abbreviation", "Adjective", "Adverb", "Citation", "Ref",
  "Compound", "Pos_Conjunction", "Continue", "Copula", "Det_Quantifier",
  "Existential", "Foreign", "Interjection", "Interrogative", "Junk",
  "Modal", "Negation", "Nonstandard", "Noun", "Pos_Number",
  "Participle", "Particle", "Prefix", "Preposition", "At_Prep",
  "Shel_Prep", "Pronoun", "Propername", "Punctuation", "Suffix",
  "Pos_Unknown", "Url", "Verb", "Toinfinitive", "Bareinfinitive", "---"
]
const BASEFORM_GENDER_LIST = [0n, BASEFORM_GENDER_MASCULINE, BASEFORM_GENDER_FEMININE, BASEFORM_GENDER_MASCULINEFEMININE]
const BASEFORM_GENDER_NUM_TO_STR = ["Unspecified", "Masculine", "Feminine", "Masculinefeminine", "---"]
const BASEFORM_NUMBER_LIST = [0n, BASEFORM_NUMBER_SINGULAR, BASEFORM_NUMBER_PLURAL, BASEFORM_NUMBER_DUAL, BASEFORM_NUMBER_DUALPLURAL, BASEFORM_NUMBER_SINGULARPLURAL]
const BASEFORM_NUMBER_NUM_TO_STR = ["Unspecified", "Singular", "Plural", "Dual", "Dualplural", "Singularplural", "---"]
const BASEFORM_PERSON_LIST = [0n, BASEFORM_PERSON_1, BASEFORM_PERSON_2, BASEFORM_PERSON_3, BASEFORM_PERSON_ANY]
const BASEFORM_PERSON_NUM_TO_STR = ["Unspecified", "Person_1", "Person_2", "Person_3", "Person_Any"]
const BASEFORM_STATUS_LIST = [0n, BASEFORM_STATUS_ABSOLUTE, BASEFORM_STATUS_CONSTRUCT, BASEFORM_STATUS_ABSOLUTECONSTRUCT]
const BASEFORM_STATUS_NUM_TO_STR = ["Unspecified", "Absolute", "Construct", "Absoluteconstruct", "---"]
const BASEFORM_TENSE_LIST = [0n, BASEFORM_TENSE_OTHER, BASEFORM_TENSE_PAST, BASEFORM_TENSE_PRESENT, BASEFORM_TENSE_FUTURE, BASEFORM_TENSE_IMPERATIVE]
const BASEFORM_TENSE_NUM_TO_STR = ["Unspecified", "Tense_Other", "Past", "Present", "Future", "Imperative"]

const BASEFORM_PASSIVE_LIST = [0n, BASEFORM_PASSIVE_TRUE]
const BASEFORM_PASSIVE_NUM_TO_STR = ["Unspecified", "Passive", "---"]

//for morphology
function getPrefixLength(fullMorphBMstring) {
  if (typeof fullMorphBMstring !== 'string') {
    fullMorphBMstring = fullMorphBMstring.toString()
  }
  const fullMorphBMtoLong = BigInt(fullMorphBMstring)
  var prefixLength = Number(fullMorphBMtoLong & PREFIX_LENGTH)
  return prefixLength
}

function checkAramaic(fullMorphBMstring) {
  if (typeof fullMorphBMstring !== 'string') {
    fullMorphBMstring = fullMorphBMstring.toString()
  }
  const fullMorphBMtoLong = BigInt(fullMorphBMstring)
  var aramaic = fullMorphBMtoLong & FULLFORM_ARAMAIC
  return aramaic > 0
}

function getMorphologyStrings(fullMorphBMstring) {
  if (typeof fullMorphBMstring !== 'string') {
    fullMorphBMstring = fullMorphBMstring.toString()
  }
  const fullMorphBM = BigInt(fullMorphBMstring)
  var morphStrings = { partOfSpeech: '', gender: '', person: [], number: '', tense: [], nounType: '', positivity: 'Unspecified', conjunctionType: '', status: '', detType: '', detDef: '', prefixes: [], suffix: '', suffixPerson: '', suffixNumber: '', suffixGender: '', suffixType: '', passivity: '' }

  var toCompare = fullMorphBM & BASEFORM_POS
  if (BASEFORM_POS_LIST.indexOf(toCompare) >= 0)
    morphStrings.partOfSpeech = BASEFORM_POS_NUM_TO_STR[BASEFORM_POS_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_GENDER
  if (BASEFORM_GENDER_LIST.indexOf(toCompare) >= 0)
    morphStrings.gender = BASEFORM_GENDER_NUM_TO_STR[BASEFORM_GENDER_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_NUMBER
  if (BASEFORM_NUMBER_LIST.indexOf(toCompare) >= 0)
    morphStrings.number = BASEFORM_NUMBER_NUM_TO_STR[BASEFORM_NUMBER_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_PERSON
  BASEFORM_PERSON_LIST.forEach((bm, i) => {
    if (toCompare === bm) morphStrings.person.push(BASEFORM_PERSON_NUM_TO_STR[i])
  })

  toCompare = fullMorphBM & BASEFORM_TENSE
  BASEFORM_TENSE_LIST.forEach((bm, i) => {
    if (toCompare === bm) {
      morphStrings.tense.push(BASEFORM_TENSE_NUM_TO_STR[i])
    }
  })

  toCompare = fullMorphBM & BASEFORM_NOUNTYPE
  if (BASEFORM_NOUNTYPE_LIST.indexOf(toCompare) >= 0)
    morphStrings.nounType = BASEFORM_NOUNTYPE_NUM_TO_STR[BASEFORM_NOUNTYPE_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_STATUS
  if (BASEFORM_STATUS_LIST.indexOf(toCompare) >= 0)
    morphStrings.status = BASEFORM_STATUS_NUM_TO_STR[BASEFORM_STATUS_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_PASSIVE
  if (BASEFORM_PASSIVE_LIST.indexOf(toCompare) >= 0) {
    morphStrings.passivity = BASEFORM_PASSIVE_NUM_TO_STR[BASEFORM_PASSIVE_LIST.indexOf(toCompare)]
  }
  toCompare = fullMorphBM & BASEFORM_CONJUNCTIONTYPE
  if (BASEFORM_CONJUNCTIONTYPE_LIST.indexOf(toCompare) >= 0)
    morphStrings.conjunctionType = BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR[BASEFORM_CONJUNCTIONTYPE_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_DETTYPE
  if (BASEFORM_DETTYPE_LIST.indexOf(toCompare) >= 0)
    morphStrings.detType = BASEFORM_DETTYPE_NUM_TO_STR[BASEFORM_DETTYPE_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & BASEFORM_DETDEF
  if (BASEFORM_DETDEF_LIST.indexOf(toCompare) >= 0)
    morphStrings.detDef = BASEFORM_DETDEF_NUM_TO_STR[BASEFORM_DETDEF_LIST.indexOf(toCompare)]

  PREFIX_FUNCTION_LIST.forEach((bm, i) => {
    if ((fullMorphBM & bm) === bm) {
      morphStrings.prefixes.push(PREFIX_FUNCTION_NUM_TO_STR[i])
    }
  })

  toCompare = fullMorphBM & SUFFIX_FUNCTION
  // console.log(`trying suffix func: ${toCompare}, ${fullMorphBM} & ${SUFFIX_FUNCTION}`)
  // console.log(`yields ${SUFFIX_FUNCTION_LIST.indexOf(toCompare)}`)
  if (SUFFIX_FUNCTION_LIST.indexOf(toCompare) >= 0)
    morphStrings.suffix = SUFFIX_FUNCTION_NUM_TO_STR[SUFFIX_FUNCTION_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & SUFFIX_TYPE
  if (SUFFIX_TYPE_LIST.indexOf(toCompare) >= 0)
    morphStrings.suffixType = SUFFIX_TYPE_NUM_TO_STR[SUFFIX_TYPE_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & SUFFIX_PERSON
  if (SUFFIX_PERSON_LIST.indexOf(toCompare) >= 0)
    morphStrings.suffixPerson = SUFFIX_PERSON_NUM_TO_STR[SUFFIX_PERSON_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & SUFFIX_GENDER
  if (SUFFIX_GENDER_LIST.indexOf(toCompare) >= 0)
    morphStrings.suffixGender = SUFFIX_GENDER_NUM_TO_STR[SUFFIX_GENDER_LIST.indexOf(toCompare)]

  toCompare = fullMorphBM & SUFFIX_NUMBER
  if (SUFFIX_NUMBER_LIST.indexOf(toCompare) >= 0)
    morphStrings.suffixNumber = SUFFIX_NUMBER_NUM_TO_STR[SUFFIX_NUMBER_LIST.indexOf(toCompare)]

  return morphStrings
}


export {
  getMorphologyStrings,
  getPrefixLength,
  checkAramaic,
  BASEFORM_POS_NUM_TO_STR,
  BASEFORM_PERSON_NUM_TO_STR,
  BASEFORM_GENDER_NUM_TO_STR,
  BASEFORM_STATUS_NUM_TO_STR,
  BASEFORM_NUMBER_NUM_TO_STR,
  BASEFORM_TENSE_NUM_TO_STR,
  PREFIX_FUNCTION_NUM_TO_STR,
  SUFFIX_FUNCTION_NUM_TO_STR,
  SUFFIX_NUMBER_NUM_TO_STR,
  SUFFIX_GENDER_NUM_TO_STR,
  SUFFIX_PERSON_NUM_TO_STR,
  SUFFIX_TYPE_NUM_TO_STR,
  BASEFORM_NOUNTYPE_NUM_TO_STR,
  BASEFORM_POSITIVITY_NUM_TO_STR,
  BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR,
  BASEFORM_DETTYPE_NUM_TO_STR,
  BASEFORM_DETDEF_NUM_TO_STR,
  BASEFORM_PASSIVE_NUM_TO_STR
}
