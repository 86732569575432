import { StateChanges, BlankState } from "./stateChanges"
import { RunStates } from "./runStates"
import router from "../router"
import { getPrefixLength } from "../js/morphologies" //checkAramaic, getMorphologyStrings
import $ from "jquery"
import { firebaseStore } from "shared/account"
import { stringWithoutNikud } from "shared/js/commonHebrew"
window.dataLayer = window.dataLayer || []
import _ from "lodash"
//import store from '../store'
function gtag() {
  window.dataLayer.push(arguments)
}

const helperFunctions = {
  ADD_CLASS(state, val, index) {
    if (!index) index = state.morphologyApp.currentWordIndex
    let classes = state.morphologyApp.morphData[index].classes
    if (classes.indexOf(val) < 0) {
      classes.push(val)
    }
  },
  REMOVE_CLASS(state, val, index) {
    if (!index) index = state.morphologyApp.currentWordIndex
    let classes = state.morphologyApp.morphData[index].classes
    if (classes.indexOf(val) > -1) {
      classes.splice(classes.indexOf(val), 1)
    }
  },
}

// eslint-disable-next-line no-misleading-character-class
const NIKUD_SPLIT = /(?=[^\u05b0-\u05bd\u05c1\u05c2\u05c7])/

function parseNested(str) {
  try {
    return JSON.parse(str, (_, val) => {
      if (typeof val === "string" && !/^\d+$/.test(val)) {
        return parseNested(val)
      }
      return val
    })
  } catch (exc) {
    return str
  }
}

function addResponse(state, responseData) {
  state.morphologyApp.morphApiResponse =
    state.morphologyApp.morphApiResponse.concat(responseData)
  var index = state.morphologyApp.lastWordIndex
  var keyIndex = state.morphologyApp.lastWordKey
  let fileHasSelected =
    state.morphologyApp.morphApiResponse[0] &&
    state.morphologyApp.morphApiResponse[0].word.trim() === "@@@@"
  var wordToTag = !fileHasSelected
  state.morphologyApp.morphData = state.morphologyApp.morphData.concat(
    responseData.map((segment) => {
      if (!segment.sep) {
        index++
      } else {
        if (fileHasSelected) {
          wordToTag =
            segment.word.trim().includes("@@") &&
            !segment.word.trim().includes("@@@@")
          if (state.morphologyApp.currentWordIndex === 0 && wordToTag)
            //select first word to tag
            state.morphologyApp.currentWordIndex = keyIndex + 1
        }
      }
      if (fileHasSelected && !wordToTag) {
        segment.options = []
      }
      const morphObj = {
        text: segment.word,
        display: createDisplay(
          fileHasSelected ? segment.word.replaceAll("@@", "") : segment.word,
          segment.options.length > 0
            ? getPrefixLength(segment.options[0].morph, false)
            : 0
        ),
        sep: segment.sep,
        selctedMorphIndex: 0,
        selectedMorph: {},
        options: [],
        isPasuk: segment.fpasuk,
        wordIndex: index,
        actualIndex: keyIndex,
        comments: "",
        groupRange: [],
        groupId: null,
        selectedGroupMorphIndex: 0,
        groupData: [],
        selectedGroupMorph: {},
        neutralized: false,
        neutralizedRange: [],
        withNikud: true,
        flagged: false,
        classes: [
          segment.sep ? "sep" : "",
          wordToTag && fileHasSelected ? "font-weight-bold" : "",
        ],
        tagWord: wordToTag && !segment.sep,
        isEditable: !segment.sep && wordToTag,
        morphArrs: segment.options, //(wordToTag && fileHasSelected) || !fileHasSelected ? segment.options : []
      }
      keyIndex++
      return morphObj
    })
  )
  state.morphologyApp.lastWordIndex = index
  state.morphologyApp.lastWordKey = keyIndex
}

/**
 * Deletes a group from the file
 * @param state
 * @param {number[]} groupRange
 */
function deleteGroup(state, groupRange) {
  for (let j = 0; j <= groupRange.length; j++) {
    const token = state.morphologyApp.morphData[groupRange[j]]
    if (token !== undefined) {
      if (j === 0) {
        if (token.classes.includes("group-begin"))
          token.classes.splice(token.classes.indexOf("group-begin"), 1)

        //remove user created morph items for group (items that are not in dictionary will be removed permanently)
        token.groupData = token.groupData.filter((obj) => obj.fromServer)
      }

      if (j === groupRange.length - 1) {
        if (token.classes.includes("group-end"))
          token.classes.splice(token.classes.indexOf("group-end"), 1)
      }

      if (!token.sep && !token.neutralized) token.isEditable = true

      while (token.classes.includes("grouped"))
        token.classes.splice(token.classes.indexOf("grouped"), 1)

      token.groupId = null
      token.groupRange = []

      //restore prefix and edited display for single words in group
      if (!token.sep && token.selectedMorph) {
        const prefixLength =
          token.selectedMorph.prefixLength ||
          (token.options.length > 0
            ? token.options[token.selctedMorphIndex].prefixLength
            : 0)
        token.display = createDisplay(token.text, prefixLength)

        if (
          token.selectedMorph.optionIndex &&
          token.selectedMorph.optionIndex > 0
        ) {
          helperFunctions.ADD_CLASS(state, "edited", groupRange[j])
        }
      }
    }

    let fileHasSelected =
      state.morphologyApp.morphApiResponse[0] &&
      state.morphologyApp.morphApiResponse[0].word.trim() === "@@@@"
    if (fileHasSelected && token) {
      //disable first word of group in the case of a @@@@ file
      if (!token.tagWord) {
        token.isEditable = false
        token.classes.splice(token.classes.indexOf("font-weight-bold"), 1)
      }
    }
  }
}

function createGroup(state, tokenArr) {
  // If overriding an existing group, delete it completely first
  for (let i = 0; i < tokenArr.length; i++) {
    const word = state.morphologyApp.morphData[tokenArr[i]]
    if (word.groupId) deleteGroup(state, word.groupRange)
  }

  if (
    !state.morphologyApp.morphData[tokenArr[0]].classes.includes("group-begin")
  )
    state.morphologyApp.morphData[tokenArr[0]].classes.push("group-begin")
  let fileHasSelected =
    state.morphologyApp.morphApiResponse[0] &&
    state.morphologyApp.morphApiResponse[0].word.trim() === "@@@@"
  if (fileHasSelected) {
    //enable first word of group in the case of a @@@@ file
    state.morphologyApp.morphData[tokenArr[0]].isEditable = true
  }
  state.morphologyApp.morphData[tokenArr[0]].groupId = tokenArr[0]
  state.morphologyApp.morphData[tokenArr[0]].groupRange = tokenArr
  state.recentGroupedRange = tokenArr
  //state.morphologyApp.morphData[tokenArr[0]].groupData = state.morphologyApp.morphData[tokenArr[0]].options.map(a => Object.assign({}, a))
  if (
    !state.morphologyApp.morphData[
      tokenArr[tokenArr.length - 1]
    ].classes.includes("group-end")
  )
    state.morphologyApp.morphData[tokenArr[tokenArr.length - 1]].classes.push(
      "group-end"
    )
  for (var i = 0; i < tokenArr.length; i++) {
    if (state.morphologyApp.morphData[tokenArr[i]] !== undefined) {
      if (i > 0) {
        state.morphologyApp.morphData[tokenArr[i]].isEditable = false
      }
      if (
        !state.morphologyApp.morphData[tokenArr[i]].classes.includes("grouped")
      )
        state.morphologyApp.morphData[tokenArr[i]].classes.push("grouped")
      if (
        fileHasSelected &&
        !state.morphologyApp.morphData[tokenArr[i]].classes.includes(
          "font-weight-bold"
        )
      ) {
        state.morphologyApp.morphData[tokenArr[i]].classes.push(
          "font-weight-bold"
        )
      }
      state.morphologyApp.morphData[tokenArr[i]].groupId = tokenArr[0]
      state.morphologyApp.morphData[tokenArr[i]].groupRange = tokenArr
      state.morphologyApp.morphData[tokenArr[i]].display = createDisplay(
        state.morphologyApp.morphData[tokenArr[i]].text,
        0
      )
      if (i == 0) {
        if (state.morphologyApp.morphData[tokenArr[i]].groupData.length > 0)
          //set prefix for values coming from server
          state.morphologyApp.morphData[tokenArr[i]].display = createDisplay(
            state.morphologyApp.morphData[tokenArr[i]].text,
            state.morphologyApp.morphData[tokenArr[i]].groupData[0].prefixLength
          )
      }
      if (state.morphologyApp.morphData[tokenArr[i]].classes.includes("edited"))
        helperFunctions.REMOVE_CLASS(state, "edited", tokenArr[i])
    }
  }
}
function createDisplay(word, prefixLength) {
  let display = word //.replace(/.\u05bd/g, '')
  if (prefixLength > 0) {
    const displayLetters = display /*.replace(/.\u05bd/g, '')*/
      .split(NIKUD_SPLIT)
    const prefix = displayLetters.slice(0, prefixLength).join("")
    const suffix = displayLetters.slice(prefixLength).join("")
    display = [{ text: prefix, classes: ["prefix"] }, { text: suffix }]
  }
  return display
}
const basicMutations = {
  [StateChanges.DISABLE_LOGIN_POPUP]: function (state, val) {
    state.hideLoginPopup = val
  },
  [StateChanges.NAKDAN_RUNNING]: function (state) {
    state.nakdanApiState = RunStates.RUNNING
  },
  [StateChanges.NAKDAN_CONTINUING]: function (state) {
    state.nakdanApiState = RunStates.CONTINUING
  },
  [StateChanges.NAKDAN_FAILED]: function (state) {
    state.nakdanApiState = RunStates.FAILED
  },
  [StateChanges.NAKDAN_COMPLETE]: function (state) {
    state.nakdanApiState = RunStates.COMPLETE
  },
  [StateChanges.NAKDAN_TIMED_OUT]: function (state) {
    state.nakdanApiState = RunStates.TIMED_OUT
  },
  [StateChanges.NAKDAN_NOT_RUN]: function (state) {
    state.nakdanApiState = RunStates.NOT_RUN
  },
  [StateChanges.LOAD_DOCUMENT_FILE]: function (state, documentFile) {
    state.originalDocument.source = documentFile
    gtag("event", "load file", {
      event_category: "user input",
      event_label: "loaded file of type: " + documentFile.type,
    })
  },
  [StateChanges.SET_WORD_NIKUD]: function (state, val) {
    state.morphologyApp.morphData[
      state.morphologyApp.currentWordIndex
    ].withNikud = val
  },
  [StateChanges.SET_TERM_NAMES]: function (state) {
    state.shortMorphTerms = !state.shortMorphTerms
  },
  [StateChanges.RESET_RESULTS]: function (state) {
    state.morphologyApp.morphData = []
    state.morphologyApp.morphApiResponse = []
    state.morphologyApp.currentWordIndex = 0
    state.morphologyApp.textForNakdanApiCall = ""
    state.morphologyApp.lastWordIndex = 0
    state.morphologyApp.lastWordKey = 0
    state.dropbox.status = null
    state.alternateResults = []
    localStorage.removeItem("morphology-dropbox-connection-data")
  },
  [StateChanges.LOAD_SAVED_DATA]: function (state, data) {
    const copy = JSON.parse(JSON.stringify(data))
    copy.morphData.forEach((token) => {
      if (!token.selectedMorph) Object.assign(token, { selectedMorph: {} })
    })
    state.lastLoadedState = copy
    //console.log(state.lastLoadedState)
    state.morphologyApp.morphApiResponse = copy.morphApiResponse
    state.morphologyApp.morphData = copy.morphData
    state.morphologyApp.textForNakdanApiCall = copy.textForNakdanApiCall
    state.morphologyApp.currentWordIndex = copy.currentWordIndex
    state.morphologyApp.postSubmissionOptions = copy.postSubmissionOptions
    state.nakdanApiState = RunStates.COMPLETE
    if (router.currentRoute.name !== "results") router.push({ name: "results" })
  },
  [StateChanges.SET_MORPH_RESULTS]: function (state, responseData) {
    state.morphologyApp.currentWordIndex = 0
    state.morphologyApp.lastWordIndex = 0
    state.morphologyApp.morphApiResponse = []
    state.morphologyApp.morphData = []
    state.alternateResults = []
    addResponse(state, responseData)
  },
  [StateChanges.ADD_MORPH_RESULTS_FROM_JSON]: function (state, responseData) {
    responseData = parseNested(JSON.parse(responseData))
    state.morphologyApp.currentWordIndex = 0
    state.morphologyApp.morphApiResponse = responseData
    var index = state.morphologyApp.lastWordIndex
    var keyIndex = state.morphologyApp.lastWordKey

    state.morphologyApp.morphData = state.morphologyApp.morphApiResponse.map(
      (segment) => {
        if (!segment.sep) {
          index++
        }
        let wordToTag = segment.options.length > 0
        if (state.morphologyApp.currentWordIndex === 0 && wordToTag)
          //select first word to tag
          state.morphologyApp.currentWordIndex = keyIndex
        const morphObj = {
          text: segment.word,
          display: createDisplay(
            index === 0 ? segment.word.replaceAll("@@", "") : segment.word,
            wordToTag ? getPrefixLength(segment.options[0].morph, false) : 0
          ),
          sep: segment.sep,
          selctedMorphIndex: 0,
          selectedMorph: {},
          options: [],
          isPasuk: segment.fpasuk,
          wordIndex: index,
          actualIndex: keyIndex,
          ExtraInfo: segment.ExtraInfo,
          comments: "",
          groupRange: [],
          groupId: null,
          selectedGroupMorphIndex: 0,
          groupData: [],
          selectedGroupMorph: {},
          neutralized: false,
          neutralizedRange: [],
          withNikud: true,
          flagged: false,
          classes: [
            segment.sep ? "sep" : "",
            wordToTag ? "font-weight-bold" : "",
          ],
          tagWord: wordToTag && !segment.sep,
          isEditable: !segment.sep && wordToTag,
          morphArrs: segment.options, //(wordToTag && fileHasSelected) || !fileHasSelected ? segment.options : []
        }
        keyIndex++

        return morphObj
      }
    )
    if (router.currentRoute.name !== "results") router.push({ name: "results" })
    state.nakdanApiState = RunStates.COMPLETE
  },
  [StateChanges.ADD_MORPH_RESULTS]: function (state, responseData) {
    addResponse(state, responseData)
    if (router.currentRoute.name !== "results") router.push({ name: "results" })
    state.nakdanApiState = RunStates.COMPLETE
  },
  [StateChanges.SET_MORPH_OPTIONS]: function (state, data) {
    state.morphologyApp.morphData[
      state.morphologyApp.currentWordIndex
    ].options = data
  },
  [StateChanges.MOVE_MORPH_OPTION_TO_TOP]: function (state, data) {
    let obj = data.contextData
    let index = obj.index
    let pinned = obj.pinned
    let currentWordIndex = state.morphologyApp.currentWordIndex
    let optionToMove =
      state.morphologyApp.morphData[currentWordIndex].options[index]
    state.morphologyApp.morphData[currentWordIndex].options.splice(index, 1)
    if (pinned) {
      optionToMove.pinned = true
      optionToMove.indexForUnpin = index
      state.morphologyApp.morphData[currentWordIndex].options.splice(
        0,
        0,
        optionToMove
      )
      state.morphologyApp.morphData[currentWordIndex].selectedMorph =
        optionToMove
    } else {
      state.morphologyApp.morphData[currentWordIndex].options.splice(
        1,
        0,
        optionToMove
      )
    }
  },
  [StateChanges.TOGGLE_WORDS_TO_TAG]: function (state) {
    state.morphologyApp.morphData.map((el) => {
      if (!el.tagWord) {
        if (!el.sep) el.isEditable = !el.isEditable
        el.neutralized = !el.neutralized
      } else {
        //toggle bold font
        if (el.classes.indexOf("font-weight-bold") > -1)
          el.classes.splice(el.classes.indexOf("font-weight-bold"), 1)
        else el.classes.push("font-weight-bold")
      }
    })
  },
  [StateChanges.MOVE_MORPH_OPTION_TO_BOTTOM]: function (state, data) {
    let obj = data.contextData
    let index = obj.index
    let toIndex = obj.toIndex
    let currentWordIndex = state.morphologyApp.currentWordIndex
    let morphOptions = state.morphologyApp.morphData[currentWordIndex].options
    morphOptions[index].indexForRestore = index
    //morphOptions.push(morphOptions.splice(index, 1)[0])
    let temp = morphOptions[index]
    morphOptions.splice(index, 1)
    morphOptions.splice(toIndex, 0, temp)
  },
  [StateChanges.EDIT_MORPH_OPTION]: function (state, data) {
    let currentWordIndex = state.morphologyApp.currentWordIndex
    let editedMorphIndex = state.morphologyApp.morphData[
      currentWordIndex
    ].options.indexOf(data.contextData.morph)
    state.morphologyApp.morphData[currentWordIndex].options.splice(
      editedMorphIndex,
      1,
      data.contextData.newMorph
    )
  },
  [StateChanges.SET_GROUP_MORPH_OPTION]: function (state, newObject) {
    let groupId =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
        .groupId
    state.morphologyApp.morphData[groupId].groupData.splice(
      state.morphologyApp.morphData[groupId].selctedMorphIndex,
      1,
      newObject
    )
  },
  [StateChanges.ADD_MORPH_OPTION]: function (state, newObject) {
    let currentToken =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    let fileHasSelected =
      state.morphologyApp.morphApiResponse[0] &&
      state.morphologyApp.morphApiResponse[0].word.trim() === "@@@@"
    if (fileHasSelected && !currentToken.tagWord) {
      // currentToken.tagWord = true
      currentToken.isEditable = true
      currentToken.classes.push("font-weight-bold")
    }
    if (newObject.pinned) currentToken.options.splice(0, 0, newObject)
    else currentToken.options.splice(1, 0, newObject)
  },
  [StateChanges.PUSH_MORPH_OPTION]: function (state, newObject) {
    newObject.onlyNikud = false
    state.morphologyApp.morphData[
      state.morphologyApp.currentWordIndex
    ].options.push(newObject)
  },
  [StateChanges.INSERT_MORPH_OPTION]: function (state, newObject) {
    if (newObject.pinned) {
      let temp =
        state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
          .options[0]
      let toIndex = temp.indexForUnpin
      delete temp.indexForUnpin
      delete temp.pinned
      state.morphologyApp.morphData[
        state.morphologyApp.currentWordIndex
      ].options.splice(0, 1)
      state.morphologyApp.morphData[
        state.morphologyApp.currentWordIndex
      ].options.splice(toIndex, 0, temp)
    }
    let tempObj = newObject
    let tempOptions = {
      partOfSpeech: newObject.morphOptions.partOfSpeech,
      gender: newObject.morphOptions.gender,
      person: newObject.morphOptions.person,
      number: newObject.morphOptions.number,
      tense: newObject.morphOptions.tense,
      nounType: newObject.morphOptions.nounType,
      positivity: newObject.morphOptions.positivity,
      passivity: newObject.morphOptions.passivity,
      nominalized: newObject.morphOptions.nominalized,
      nominalizedAdjective: newObject.morphOptions.nominalizedAdjective,
      nomConstruct: newObject.morphOptions.nomConstruct,
      continued: newObject.morphOptions.continued,
      enablesInfinitive: newObject.morphOptions.enablesInfinitive,
      hagam: newObject.morphOptions.hagam,
      regressivePrefixLetter: newObject.morphOptions.regressivePrefixLetter,
      arabic: newObject.morphOptions.arabic,
      foreign: newObject.morphOptions.foreign,
      nikudInaccurate: newObject.morphOptions.nikudInaccurate,
      conjunctionType: newObject.morphOptions.conjunctionType,
      status: newObject.morphOptions.status,
      detType: newObject.morphOptions.detType,
      detDef: newObject.morphOptions.detDef,
      prefixes: newObject.morphOptions.prefixes,
      suffix: newObject.morphOptions.suffix,
      suffixNumber: newObject.morphOptions.suffixNumber,
      suffixPerson: newObject.morphOptions.suffixPerson,
      suffixGender: newObject.morphOptions.suffixGender,
      suffixType: newObject.morphOptions.suffixType,
    }
    tempObj.morphOptions = tempOptions
    if (newObject.pinned)
      state.morphologyApp.morphData[
        state.morphologyApp.currentWordIndex
      ].options.splice(0, 0, tempObj)
    else
      state.morphologyApp.morphData[
        state.morphologyApp.currentWordIndex
      ].options.splice(1, 0, tempObj)
  },
  [StateChanges.ADD_GROUP_MORPH_OPTION]: function (state, newObject) {
    let groupId =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
        .groupId
    state.morphologyApp.morphData[groupId].groupData.unshift(newObject)
  },
  [StateChanges.SET_TEXT_TYPE]: function (state, textType) {
    state.morphologyApp.textType = textType
  },
  [StateChanges.SET_TEXT_FOR_NAKDAN_API_CALL]: function (state, newText) {
    state.morphologyApp.textForNakdanApiCall = newText
  },
  [StateChanges.SET_CURRENT_WORD_INDEX]: function (state, index) {
    state.morphologyApp.currentWordIndex = index
  },
  [StateChanges.SET_SELECTED_MORPH]: function (state, obj) {
    let currentToken =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    currentToken.selectedMorph = obj
    currentToken.display = createDisplay(
      currentToken.text,
      currentToken.selectedMorph ? currentToken.selectedMorph.prefixLength : 0
    )
    if (!_.isEqual(currentToken.options[0], obj)) {
      helperFunctions.ADD_CLASS(state, "edited")
    } else {
      helperFunctions.REMOVE_CLASS(state, "edited")
    }
  },
  [StateChanges.SET_CURRENT_MORPH_INDEX]: function (state, index) {
    let currentToken =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    currentToken.selctedMorphIndex = index
    currentToken.display = createDisplay(
      currentToken.text,
      currentToken.options ? currentToken.options[index].prefixLength : 0
    )
    if (index !== 0) {
      helperFunctions.ADD_CLASS(state, "edited")
    } else {
      helperFunctions.REMOVE_CLASS(state, "edited")
    }
  },
  [StateChanges.SET_GROUP_SELECTED_MORPH]: function (state, obj) {
    let currentWordIndex = state.morphologyApp.currentWordIndex
    let currentGroupId = state.morphologyApp.morphData[currentWordIndex].groupId
    let currentToken = state.morphologyApp.morphData[currentGroupId]
    currentToken.selectedGroupMorph = obj
    currentToken.display = createDisplay(
      currentToken.text,
      currentToken.selectedGroupMorph.prefixLength
    )
  },
  [StateChanges.SET_CURRENT_GROUP_MORPH_INDEX]: function (state, index) {
    let currentWordIndex = state.morphologyApp.currentWordIndex
    let currentGroupId = state.morphologyApp.morphData[currentWordIndex].groupId
    state.morphologyApp.morphData[currentGroupId].selectedGroupMorphIndex =
      index
  },
  [StateChanges.SET_NEUTRALIZED]: function (state, { mutateTo, wordIndex }) {
    if (wordIndex !== state.morphologyApp.currentWordIndex) {
      state.morphologyApp.currentWordIndex = wordIndex
    }
    let tokenArr = mutateTo
    var isNeutral = true
    for (var i = 0; i <= tokenArr.length; i++) {
      if (state.morphologyApp.morphData[tokenArr[i]] !== undefined) {
        if (
          !state.morphologyApp.morphData[tokenArr[i]].neutralized &&
          !state.morphologyApp.morphData[tokenArr[i]].sep
        )
          isNeutral = false
      }
    }
    if (tokenArr.length === 1 && isNeutral) {
      //prevent single neutralized seps
      if (state.morphologyApp.morphData[tokenArr[0] - 1] !== undefined) {
        if (
          state.morphologyApp.morphData[tokenArr[0] - 1].neutralized &&
          state.morphologyApp.morphData[tokenArr[0] - 1].sep
        ) {
          tokenArr.push(tokenArr[0] - 1)
        }
      }
      if (state.morphologyApp.morphData[tokenArr[0] + 1] !== undefined) {
        if (
          state.morphologyApp.morphData[tokenArr[0] + 1].neutralized &&
          state.morphologyApp.morphData[tokenArr[0] + 1].sep
        ) {
          tokenArr.push(tokenArr[0] + 1)
        }
      }
    }
    for (i = 0; i <= tokenArr.length; i++) {
      if (state.morphologyApp.morphData[tokenArr[i]] !== undefined) {
        state.morphologyApp.morphData[tokenArr[i]].neutralized = !isNeutral
        if (!isNeutral) {
          if (
            !state.morphologyApp.morphData[tokenArr[i]].classes.includes(
              "neutralized"
            )
          )
            state.morphologyApp.morphData[tokenArr[i]].classes.push(
              "neutralized"
            )
          state.morphologyApp.morphData[tokenArr[i]].isEditable = false
          if (tokenArr.length > 1) {
            state.morphologyApp.morphData[tokenArr[i]].neutralizedRange =
              tokenArr
          }
        } else {
          state.morphologyApp.morphData[tokenArr[i]].neutralizedRange = []
          while (
            state.morphologyApp.morphData[tokenArr[i]].classes.includes(
              "neutralized"
            )
          )
            state.morphologyApp.morphData[tokenArr[i]].classes.splice(
              state.morphologyApp.morphData[tokenArr[i]].classes.indexOf(
                "neutralized"
              ),
              1
            )
          //re-enable editing if not number or space
          if (!state.morphologyApp.morphData[tokenArr[i]].sep) {
            if (
              state.morphologyApp.morphData[tokenArr[i]].groupRange.indexOf(
                tokenArr[i]
              ) !== 0
            )
              state.morphologyApp.morphData[tokenArr[i]].isEditable = true
          }
        }
      }
    }
  },
  [StateChanges.SET_GROUP]: function (state, obj) {
    let tokenArr = obj.groupRage
    const firstWord = state.morphologyApp.morphData[tokenArr[0]]
    if (!firstWord.groupId) {
      createGroup(state, tokenArr)
      if (obj.showToast) {
        $("#group-created-msg").fadeIn()
        setTimeout(() => {
          $("#group-created-msg").fadeOut()
        }, 2000)
      }
    } else {
      deleteGroup(state, firstWord.groupRange)
    }
  },
  /* This mutation initializes a group for an NGram. `objArr` is a list of NGram morph options, which will be
      used to determine the members of the NGram group. User-created groups are created using another mutation. */
  [StateChanges.INITIALIZE_GROUP]: function (state, objArr) {
    let currentIndex = state.morphologyApp.currentWordIndex
    let range = []
    if (state.morphologyApp.morphData[currentIndex].groupRange.length == 0) {
      //first word in group
      range.push(currentIndex)

      let NGramIDs = []
      objArr.map((element) => {
        NGramIDs.push(element.NGramID)
      })
      let i = currentIndex + 1
      while (i < state.morphologyApp.morphApiResponse.length) {
        if (
          !state.morphologyApp.morphApiResponse[i].sep &&
          state.morphologyApp.morphApiResponse[i].options.length > 0
        ) {
          let filtered = state.morphologyApp.morphApiResponse[i].options.filter(
            (item) => {
              return NGramIDs.indexOf(item.NGramID) >= 0
            }
          )
          if (filtered.length > 0) {
            range.push(i)
          } else {
            break
          }
        }
        i++
      }
    }

    let f = range[0]
    let l = range[range.length - 1]
    let tokenArr = []
    for (let i = f; i <= l; i++) {
      tokenArr.push(i)
    }

    // Don't override existing groups automatically
    for (let i = 0; i < tokenArr.length; i++) {
      if (state.morphologyApp.morphData[tokenArr[i]].groupId) return
    }

    if (tokenArr.length > 1) {
      createGroup(state, tokenArr)
      //add data
      objArr.forEach((item) => {
        delete item.NGramID
        state.morphologyApp.morphData[f].groupData.unshift(item)
      })
    }
  },
  [StateChanges.SET_PREFIX]: function (state, value) {
    const wordIndex = state.morphologyApp.currentWordIndex
    const currentWord = state.morphologyApp.morphData[wordIndex]
    let morphIndex = currentWord.selctedMorphIndex
    let word = currentWord.text
    if (value >= 0 && value <= word.length && value < 6) {
      currentWord.options[morphIndex].prefixLength = value
      currentWord.display = createDisplay(currentWord.text, value)
    }
  },
  [StateChanges.SET_CURRENT_MORPH_COMMENTS]: function (state, text) {
    let wordIndex = state.morphologyApp.currentWordIndex
    state.morphologyApp.morphData[wordIndex].comments = text
    if (text.length) helperFunctions.ADD_CLASS(state, "comment")
    else helperFunctions.REMOVE_CLASS(state, "comment")
  },
  [StateChanges.TOGGLE_LEX_ENABLED]: function (state, index) {
    let wordIndex = state.morphologyApp.currentWordIndex
    state.morphologyApp.morphData[wordIndex].options[index].lexEnabled =
      !state.morphologyApp.morphData[wordIndex].options[index].lexEnabled
  },
  [StateChanges.TOGGLE_GENERAL_FLAG]: function (state, wordIndex) {
    if (wordIndex !== state.morphologyApp.currentWordIndex) {
      state.morphologyApp.currentWordIndex = wordIndex
    }
    state.morphologyApp.morphData[wordIndex].flagged =
      !state.morphologyApp.morphData[wordIndex].flagged
    if (state.morphologyApp.morphData[wordIndex].flagged) {
      helperFunctions.ADD_CLASS(state, "flag")
    } else {
      helperFunctions.REMOVE_CLASS(state, "flag")
    }
  },
  [StateChanges.SET_DISABLE_BACK_TO_TEXT_PREP_PROMPT]: function (
    state,
    newDisableBackToTextPrepPrompt
  ) {
    state.morphologyApp.postSubmissionOptions.disableBackToTextPrepPrompt =
      newDisableBackToTextPrepPrompt
  },
  /**
   * Modify the user dictionary
   * Six operations are supported: 4 are self-explanatory: 'add'|'edit'|'remove'|'removeNoMessage'
   * 'hide' and 'unhide' mean to add the selected option to a list of invalid morphology options, and unhide means to
   * remove from that list, making it a valid choice again. "Hidden" options are actually shown, but listed in grey
   * at the end of the list.
   * @param state
   * @param {{ operation: 'add'|'edit'|'remove'|'removeNoMessage'|'hide'|'unhide', morphObj: {} }} data
   */
  [StateChanges.UPDATE_USER_DICTIONARY]: function (state, data) {
    if (!state.account.isLoggedIn) {
      // eslint-disable-next-line no-console
      console.error("dictionary requires login")
      return
    }
    if (data.newUserDictionaryJson) {
      // eslint-disable-next-line no-console
      console.error("resetting the dictionary is not currently supported.")
      return
    }
    const { operation, morphObj: currentOption } = data.contextData
    let aramaic = currentOption.aramaic ? "-ארמית" : ""
    // wordKey is a single string that represents the actual menukad word along with its base word, and ארמית if it's Aramaic
    // This can be used to find the list of morphology choices given those facts; note that there might be
    // many keys for a given set of letters.
    let wordKey =
      currentOption.returnedWord.replace(/\|/g, "") +
      " (" +
      currentOption.word +
      aramaic +
      ")"
    if (operation === "add" || operation === "edit") {
      // first, make sure the new option is marked as a custom option if it didn't previously have `onlyNikud` set.
      // onlyNikud seems to mark an option as appropriate for this nikud; we might be using an option that was
      // previously only listed for these letters with other nikud.
      if (!currentOption.onlyNikud) {
        currentOption.onlyNikud = true
        currentOption.fromServer = false
        currentOption.returnedWord =
          state.morphologyApp.morphData[
            state.morphologyApp.currentWordIndex
          ].text
      }
      const wordKeyEntries =
        state.account.sync.morphologyDictionary.entryPresent[wordKey] || []
      let indexFound = _.findIndex(wordKeyEntries, {
        morphOptions: currentOption.morphOptions,
      })
      if (indexFound < 0) {
        wordKeyEntries.push(currentOption)
      }
      firebaseStore(
        ["morphologyDictionary", "entryPresent", wordKey],
        wordKeyEntries
      )
      if (operation === "add") {
        $("#item-added-msg").fadeIn()
        setTimeout(() => {
          $("#item-added-msg").fadeOut()
        }, 2000)
      }
    } else if (operation === "remove" || operation === "removeNoMessage") {
      if (!currentOption.fromServer) {
        // The option is actually stored with each word that it might apply to,
        // so delete if not in use from all the words in our current text.
        let word = currentOption.returnedWord.replace("|", "")
        state.morphologyApp.morphData
          .filter((c) => c.text === word)
          .map((element) => {
            element.options.forEach((item, key) => {
              if (
                _.isEqual(
                  _.omit(item, [
                    "optionIndex",
                    "pinned",
                    "indexForUnpin",
                    "flag",
                    "fromServer",
                  ]),
                  _.omit(currentOption, [
                    "optionIndex",
                    "pinned",
                    "indexForUnpin",
                    "flag",
                    "fromServer",
                  ])
                ) &&
                !_.isEqual(element.selectedMorph, item)
              ) {
                element.options.splice(key, 1)
              }
            })
          })
      }
      const wordKeyEntries =
        state.account.sync.morphologyDictionary.entryPresent[wordKey]
      let indexToDel = _.findIndex(wordKeyEntries, {
        morphOptions: currentOption.morphOptions,
      })
      if (indexToDel > -1) {
        wordKeyEntries.splice(indexToDel, 1)
        if (operation === "remove") {
          $("#item-removed-msg").fadeIn()
          setTimeout(() => {
            $("#item-removed-msg").fadeOut()
          }, 2000)
        }
        firebaseStore(
          ["morphologyDictionary", "entryPresent", wordKey],
          wordKeyEntries
        )
      }
      if (operation === "removeNoMessage" && currentOption.pinned) {
        let word = currentOption.returnedWord.replace("|", "")
        state.morphologyApp.morphData
          .filter((c) => c.text === word)
          .map((element) => {
            if (
              _.isEqual(
                _.omit(element.options[0], [
                  "optionIndex",
                  "indexForUnpin",
                  "flag",
                ]),
                _.omit(currentOption, ["optionIndex", "indexForUnpin", "flag"])
              )
            ) {
              let temp = element.options[0]
              let toIndex = temp.indexForUnpin
              delete element.options[0].indexForUnpin
              delete element.options[0].pinned
              state.morphologyApp.morphData[element.actualIndex].options.splice(
                0,
                1
              )
              state.morphologyApp.morphData[element.actualIndex].options.splice(
                toIndex,
                0,
                temp
              )
            }
          })
      }
    } else if (operation === "hide") {
      const negKeyEntries =
        state.account.sync.morphologyDictionary.negativeEntryPresent[wordKey] ||
        []
      negKeyEntries.push(currentOption)
      firebaseStore(
        ["morphologyDictionary", "negativeEntryPresent", wordKey],
        negKeyEntries
      )
    } else if (operation === "unhide") {
      let negKeyEntries =
        state.account.sync.morphologyDictionary.negativeEntryPresent[wordKey]
      let indexToDel = _.findIndex(negKeyEntries, {
        morphOptions: currentOption.morphOptions,
      })
      if (indexToDel >= 0) {
        negKeyEntries.splice(indexToDel, 1)
        firebaseStore(
          ["morphologyDictionary", "negativeEntryPresent", wordKey],
          negKeyEntries
        )
      }
      let currentWordIndex = state.morphologyApp.currentWordIndex
      let morphOptions = state.morphologyApp.morphData[currentWordIndex].options
      let fromIndex = currentOption.optionIndex
      let toIndex = currentOption.indexForRestore
      delete currentOption.indexForRestore
      morphOptions.splice(fromIndex, 1)
      morphOptions.splice(toIndex, 0, currentOption)
    }
  },
  [StateChanges.REMOVE_MORPHOLOGY]: function (state, data) {
    let currentToken =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    if (data.contextData.morph === currentToken.selectedMorph) {
      state.canDelete = false
    } else {
      state.canDelete = true
      currentToken.options.splice(data.contextData.morphIndex, 1)
      if (currentToken.selctedMorphIndex > data.contextData.morphIndex)
        currentToken.selctedMorphIndex--
      $("#item-deleted-msg").fadeIn()
      setTimeout(() => {
        $("#item-deleted-msg").fadeOut()
      }, 2000)
    }
  },
  [StateChanges.REMOVE_GROUP_MORPHOLOGY]: function (state, data) {
    let currentToken =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    let currentGroupToken = state.morphologyApp.morphData[currentToken.groupId]
    if (data.contextData.morph === currentGroupToken.selectedGroupMorph) {
      state.canDelete = false
    } else {
      state.canDelete = true
      currentGroupToken.groupData.splice(data.contextData.morphIndex, 1)
      if (
        currentGroupToken.selectedGroupMorphIndex > data.contextData.morphIndex
      )
        currentGroupToken.selectedGroupMorphIndex--
      $("#item-deleted-msg").fadeIn()
      setTimeout(() => {
        $("#item-deleted-msg").fadeOut()
      }, 2000)
    }
  },
  [StateChanges.SET_ALT_RESULTS]: function (state, data) {
    //state.nakdanApiState = RunStates.COMPLETE
    state.alternateResults = data[0].options
  },
  [StateChanges.REMOVE_ALT_RESULTS]: function (state) {
    state.alternateResults = []
  },
  [StateChanges.UPDATE_SINGLE_MORPH]: function (state, data) {
    state.morphologyApp.morphApiResponse[
      state.morphologyApp.currentWordIndex
    ].options = data
    state.morphologyApp.morphApiResponse[
      state.morphologyApp.currentWordIndex
    ].w = data[0].w
    state.morphologyApp.morphApiResponse[state.morphologyApp.currentWordIndex]
      .word
    let currentMorph =
      state.morphologyApp.morphData[state.morphologyApp.currentWordIndex]
    currentMorph.text = data[0].w.replace("|", "")
    currentMorph.morphArrs = data
    currentMorph.selectedMorph = null
    currentMorph.display = createDisplay(
      data[0].w.replace("|", ""),
      stringWithoutNikud(data[0].w).indexOf("|")
    )
    state.nakdanApiState = RunStates.UPDATE_COMPLETE
  },
  emptyState: function (state) {
    Object.assign(state, JSON.parse(JSON.stringify(BlankState)))
  },
}

export default basicMutations
