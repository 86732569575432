import {
  BASEFORM_POS_NUM_TO_STR,
  BASEFORM_PERSON_NUM_TO_STR,
  BASEFORM_GENDER_NUM_TO_STR,
  BASEFORM_STATUS_NUM_TO_STR,
  BASEFORM_NUMBER_NUM_TO_STR,
  BASEFORM_TENSE_NUM_TO_STR,
  PREFIX_FUNCTION_NUM_TO_STR,
  SUFFIX_FUNCTION_NUM_TO_STR,
  BASEFORM_NOUNTYPE_NUM_TO_STR,
  BASEFORM_POSITIVITY_NUM_TO_STR,
  BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR,
  BASEFORM_DETTYPE_NUM_TO_STR,
  BASEFORM_DETDEF_NUM_TO_STR
 } from '@/js/morphologies'

function updateFlag(morphArr, morphDescriptions) {
  const foundTerm = morphDescriptions.filter(element => morphArr.includes(element))[0]
  const flaggedIndex = morphArr.indexOf(foundTerm)
  if (flaggedIndex >= 0) {
    if (!morphArr[flaggedIndex].includes('<i class="flag"></i>'))
      morphArr[flaggedIndex] = '<i class="flag"></i>' + morphArr[flaggedIndex]
  }
}

const flagToDescriptions = {
  'table: Part-of-speech': BASEFORM_POS_NUM_TO_STR,
  'table: Gender': BASEFORM_GENDER_NUM_TO_STR,
  'table: Number': BASEFORM_NUMBER_NUM_TO_STR,
  'table: Person': BASEFORM_PERSON_NUM_TO_STR,
  'table: Status': BASEFORM_STATUS_NUM_TO_STR,
  'table: Tense': BASEFORM_TENSE_NUM_TO_STR,
  'table: NounType': BASEFORM_NOUNTYPE_NUM_TO_STR,
  'table: Conjunction': BASEFORM_CONJUNCTIONTYPE_NUM_TO_STR,
  'table: Positivity': BASEFORM_POSITIVITY_NUM_TO_STR,
  'table: Det-Type': BASEFORM_DETTYPE_NUM_TO_STR,
  'table: Det-Def': BASEFORM_DETDEF_NUM_TO_STR,
  'table: Prefixes': PREFIX_FUNCTION_NUM_TO_STR,
  'table: Suffixes': SUFFIX_FUNCTION_NUM_TO_STR,
}

export default {
  name: 'morphologyMixins',
  methods: {
    printMorphArray (morphArr, flagArr) {
      let str = ''
      let strArr = []
      if(flagArr && flagArr.length > 0) {
        flagArr.forEach(element => {
          const morphDescriptions = flagToDescriptions[element.flag]
          if (morphDescriptions) {
            updateFlag(morphArr, morphDescriptions)
          }
        })
      }

      morphArr.forEach((element, i) => {
        if (this.showShortTerms) {
          switch(element) {
            case 'Adjective':
              element = 'ADJ'
            break;
            case 'Pos_Conjunction':
              element = 'CONJ'
            break;
             case 'Adverb':
              element = 'ADV'
            break;
            case 'Continue':
              element = 'CONT'
            break;
            case 'Pronoun':
              element = 'PRON'
            break;
            case 'Pos_Number':
              element = 'NUM'
            break;
            case 'Preposition':
              element = 'PREP'
            break;
            case 'At_Prep':
              element = 'ATprep'
            break;
            case 'Shel_Prep':
              element = 'SHELprep'
            break;
            case 'Participle':
              element = 'PARTICIPLE_beinoni'
            break;
            case 'Toinfinitive':
              element = 'TO_inf'
            break;
            case 'Bareinfinitive':
              element = 'BARE_inf'
            break;
            case 'Negation':
              element = 'NEG'
            break;
            case 'Interrogative':
              element = 'Interrog'
            break;
            case 'Punctuation':
              element = 'PUNCT'
            break;
            case 'Pref_conjunction':
              element = 'pre_cconj'
            break;
            case 'Pref_definitearticle':
              element = 'pre_def'
            break;
            case 'Pref_interrogative':
              element = 'pre_interrog'
            break;
            case 'Pref_Prepositional':
              element = 'pre_prep'
            break;
            case 'Relativizer_Subordinating':
              element = 'pre_rel_subconj'
            break;
            case 'Temporal':
            case 'Pref_Temporal':
              element = 'pre_temp'
            break;
            case 'Emphatic':
              element = 'pre_QAram'
            break;
            case 'Vuvinversive':
              element = 'pre_vav_inversive'
            break;
            case 'Pref_other':
            case 'Prefix_other':
              element = 'pre_other'
            break;
            case 'Suffix_Prepositional':
              element = 'su_prep'
            break;
            case 'Suffix_Definitearticle':
              element = 'suf_def'
            break;
            case 'Pronominal':
              element = ''
            break;
            case 'Suffix_other':
              element = 'suf_other'
            break;
            case 'Accusative':
              element = 'Acc'
            break;
            case 'Possessive':
              element = 'Poss'
            break;
            case 'Nominative':
              element = 'Nom'
            break;
            case 'Suf_Masculine':
              element = 'suf_masc'
            break;
            case 'Suf_Feminine':
              element = 'suf_fem'
            break;
            case 'Suf_Masculinefeminine':
              element = 'suf_masc_fem'
            break;
            case 'Suf_Singular':
              element = 'suf_sing'
            break;
            case 'Suf_Plural':
              element = 'suf_plur'
            break;
            case 'Suf_Person_1':
              element = 'suf_p1'
            break;
            case 'Suf_Person_2':
              element = 'suf_p2'
            break;
            case 'Suf_Person_3':
              element = 'suf_p3'
            break;
            case 'Suf_Person_Any':
              element = 'suf_any'
            break;
          }
        }
    //  console.log(element)
        if (i < morphArr.length-1 && element !== '')
          str += element + ', '
        else
          str += element
      })
      strArr.push(str)
      return strArr
    }
  }
}
